import { ResponsivePieCanvas } from "@nivo/pie";
import { useEffect, useState } from "react";
import { colorCodes } from "./colorCodes";

const BodyTypeChart = ({ data }) => {
  const [bookedBodytypes, setBookedBodytypes] = useState([]);

  useEffect(() => {
    const bodyTypes = data.map(({ vehicle }) => vehicle.bodytypeName);
    setBookedBodytypes(bodyTypes);
  }, [data]);

  const countData = bookedBodytypes.reduce((counts, value) => {
    if (counts[value]) {
      counts[value]++;
    } else {
      counts[value] = 1;
    }
    return counts;
  }, {});

  const bodyTypeCount = Object.entries(countData).map(([key, count], i) => {
    return {
      id: key,
      label: key,
      value: count,
      color: colorCodes[i],
    };
  });

  console.log(bodyTypeCount);

  return (
    <ResponsivePieCanvas
      data={bodyTypeCount}
      margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
      startAngle={-84}
      innerRadius={0.7}
      padAngle={1}
      cornerRadius={8}
      activeOuterRadiusOffset={8}
      colors={{ scheme: "dark2" }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.6]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="#333333"
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "right",
          direction: "row",
          justify: false,
          translateX: -60,
          translateY: 200,
          itemsSpacing: 4,
          itemWidth: 70,
          itemHeight: 14,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: "circle",
        },
      ]}
    />
  );
};

export default BodyTypeChart;
