import { createSlice } from "@reduxjs/toolkit";

export const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceName:"",
    description:"",
    type:"interior",
    bodySpecificDetails:[]
  },
  reducers: {
    set_serviceName: (state, action) => {
      state.serviceName = action.payload;
    },
    set_description: (state, action) => {
      state.description = action.payload;
    },
    set_type: (state, action) => {
      state.type = action.payload;
    },
    set_bodySpecificDetails: (state, action) => {
      state.bodySpecificDetails = action.payload;
    },
    reset_service:(state)=>{
      state.serviceName =""
      state.description =""
      state.bodySpecificDetails =[]
    }
  },
});

export const { set_serviceName, set_description,set_type, set_bodySpecificDetails,reset_service } = serviceSlice.actions;

export default serviceSlice.reducer;
