import { CCard, CCardBody, CCardTitle } from "@coreui/react";
import React from "react";

function InformationCards({ cardData }) {
  const { color, textColor, data, header, icon } = cardData;
  return (
    <>
      <CCard
        color={color}
        textColor={textColor}
        className="mb-3 boxShadow"
        style={{ maxWidth: "19rem", minWidth: "19rem" }}
      >
        <CCardBody>
          <CCardTitle style={{ fontSize: "2rem" }}>
            <span style={{ marginRight: ".5rem" }}> {icon}</span> {data}
          </CCardTitle>
          <>{header}</>
        </CCardBody>
      </CCard>
    </>
  );
}

export default InformationCards;
