import { CButton, CSpinner } from "@coreui/react";
import { axiosInstance } from "../../../utils/axiosInstance";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
// import "./createTimeslotForm.css";
import React, { useState } from "react";
import Swal from "sweetalert2";

const DetailOfTimeSlotForm = ({ setTimeSlotData, timeSlotData, refetch }) => {
  const [loading, setLoading] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [edit, setEdit] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const convertToDefaultDateFormat = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const handleEditButtonClick = (timeSlot) => {
    setEdit(timeSlot.timeslotId);
    setStartTime(convertToDefaultDateFormat(timeSlot.startTime));
    setEndTime(convertToDefaultDateFormat(timeSlot.endTime));
  };

  const submitForm = async (data) => {
    if (typeof startTime === "number" || typeof endTime === "number") return;
    if (!startTime) return toast.warning("Start Time is required");
    if (!endTime) return toast.warning("End Time is required");

    setEditLoading(true);

    const toastFy = (message, isSuccess) => {
      const tos = isSuccess ? toast.success : toast.error;
      tos(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    try {
      const instance = await axiosInstance();
      const { data: resultData } = await instance.post("/timeslot/edit", {
        timeslotId: data.timeslotId,
        startTime,
        isAvailable: data.isAvailable,
        endTime,
      });
      console.log(timeSlotData);
      setTimeSlotData(
        timeSlotData.map((timeslot) =>
          timeslot.timeslotId === data.timeslotId
            ? { ...resultData.data, timeslotId: data.timeslotId }
            : timeslot
        )
      );
      refetch();
      setEdit("");
      resultData.status && toastFy("time-Slot Edited successfully", true);
    } catch (err) {
      !err.response.data.status &&
        toastFy(err.response.data.message || "something went wrong", false);
    } finally {
      // setLoading(false);
      setEditLoading(false);
    }
  };

  const changeStatus = async (data) => {
    setLoading(data.timeslotId);

    try {
      const instance = await axiosInstance();
      const { data: resultData } = await instance.post("/timeslot/edit", {
        timeslotId: data.timeslotId,
        startTime: convertToDefaultDateFormat(data.startTime),
        isAvailable: !data.isAvailable,
        endTime: convertToDefaultDateFormat(data.endTime),
      });
      setTimeSlotData(
        timeSlotData.map((timeslot) =>
          timeslot.timeslotId === data.timeslotId
            ? { ...resultData.data, timeslotId: data.timeslotId }
            : timeslot
        )
      );
      refetch();
      setEdit("");
      toast.success("Status updated successfuly");
    } catch (err) {
      console.log(err);
      console.log(err.response);
      toast.error("somthing went wrong !");
    } finally {
      setLoading("");
    }
  };

  const deleteTimeslot = (timeSlotId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const instance = await axiosInstance();
          await instance.post("/timeslot/delete", { timeslotId: timeSlotId });
          setTimeSlotData(
            timeSlotData.filter(
              (timeslot) => timeslot.timeslotId !== timeSlotId
            )
          );
          toast.success("TimeSlot deleted successfully");
        } catch (err) {
          toast.error(err.response.message);
        }
      }
    });
  };

  const format = "h:mm a";

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Sl.no</th>
          <th scope="col" style={{ width: "13%" }}>
            day
          </th>
          <th scope="col">Start Time</th>
          <th scope="col">End Time</th>
          <th scope="col"> Is Available </th>
          <th scope="col"> Edit </th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        {timeSlotData?.map((timeSlot, i) => (
          <tr key={timeSlot.timeslotId}>
            <th>{i + 1}</th>

            <td> {moment(timeSlot.CreatedAt).format("MMM DD, YYYY ")}</td>

            <td>
              {edit === timeSlot.timeslotId ? (
                <TimePicker
                  showSecond={false}
                  defaultValue={moment(timeSlot.startTime)}
                  className="xxx"
                  onChange={(value) => {
                    setStartTime(
                      value.toLocaleString("en-CA", {
                        timezone: "Canada/Eastern",
                      })
                    );
                  }}
                  format={format}
                  use12Hours
                  inputReadOnly
                />
              ) : (
                moment(timeSlot.startTime).format("hh:mm a")
              )}
            </td>
            <td>
              {edit === timeSlot.timeslotId ? (
                <TimePicker
                  showSecond={false}
                  defaultValue={moment(timeSlot.endTime)}
                  className="xxx"
                  onChange={(value) => {
                    setEndTime(
                      value.toLocaleString("en-CA", {
                        timezone: "Canada/Eastern",
                      })
                    );
                  }}
                  format={format}
                  use12Hours
                  inputReadOnly
                />
              ) : (
                moment(timeSlot.endTime).format("hh:mm a")
              )}
            </td>
            <td style={{ width: "13%" }}>
              <button
                title="click to change the status"
                className={
                  timeSlot.isAvailable
                    ? "btn-primary btn  rounded-pill text-white   changeStatusBtn"
                    : " btn-secondary btn  rounded-pill text-white changeStatusBtn"
                }
                type="button"
                onClick={() => changeStatus(timeSlot)}
                disabled={loading === timeSlot.timeslotId}
              >
                {loading === timeSlot.timeslotId ? (
                  <CSpinner className="small_spinner" color="light" />
                ) : timeSlot.isAvailable ? (
                  `Avaliable`
                ) : (
                  `Not Avaliable`
                )}
              </button>
            </td>

            <td style={{ width: "13%" }}>
              {edit === timeSlot.timeslotId ? (
                <>
                  <CButton
                    onClick={() => submitForm(timeSlot)}
                    color="success"
                    className="m-1"
                  >
                    {" "}
                    {editLoading ? (
                      <CSpinner className="small_spinner" color="light" />
                    ) : (
                      "✅"
                    )}{" "}
                  </CButton>
                  <CButton
                    disabled={editLoading}
                    onClick={() => setEdit("")}
                    color="dark"
                    className="m-1"
                  >
                    {" "}
                    ❌{" "}
                  </CButton>
                </>
              ) : (
                <button
                  style={{ width: "6rem" }}
                  className="btn btn-warning rounded-pill text-white "
                  type="button"
                  onClick={() => handleEditButtonClick(timeSlot)}
                >
                  Edit
                </button>
              )}
            </td>

            <td style={{ width: "13%" }}>
              <button
                class="btn btn-danger rounded-pill text-white"
                type="button"
                onClick={() => deleteTimeslot(timeSlot.timeslotId)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailOfTimeSlotForm;
