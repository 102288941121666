import React, { useRef } from "react";
import { CButton, CTooltip, CSpinner } from "@coreui/react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/axiosInstance";

function ListUsers({ editUser, data, loading }) {
  const toastId = useRef(null);

  console.log(data);

  const blockUser = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toastId.current = toast(`Deleting van -  ${data.kNumber}`, {
          autoClose: false,
          isLoading: true,
        });
        const instance = await axiosInstance();
        instance
          .post("/van/delete", {
            vanId: data._id,
          })
          .then((res) => {
            if (res.data.status) {
              toast.update(toastId.current, {
                render: "Van deleted Succesfully",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
            return toast.update(toastId.current, {
              render: error?.response?.data?.message || "something went wrong",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              isLoading: false,
            });
          });
      }
    });
  };

  return (
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Sl.no</th>
          <th scope="col">Full name </th>
          <th scope="col">Email</th>
          <th scope="col">Phone Number</th>
          <th scope="col">UID</th>
          <th scope="col">Roles</th>
          <th scope="col">View</th>
          <th scope="col">Block</th>
        </tr>
      </thead>
      {
        <tbody>
          {!loading &&
            data.sort().map((user, i) => {
              return (
                <tr key={user._id}>
                  <td>{i + 1}</td>
                  <td> {`${user.firstName} ${user.lastName}`}</td>
                  <td> {user.email}</td>
                  <td> {user.phoneNumber}</td>
                  <td>
                    <code>{user.uid}</code>
                  </td>
                  <td>
                    <CTooltip
                      content={user.roles.map((role) => `${role} ,`)}
                      placement="top"
                    >
                      <CButton
                        variant="outline"
                        color="light"
                        className="bg-trans big-str"
                      >
                        {user.roles.map(
                          (role, i) =>
                            `${role}  ${
                              i === user.roles.length - 1 ? "" : ","
                            } `
                        )}
                      </CButton>
                    </CTooltip>
                  </td>

                  {/* veiw more details button */}
                  <td>
                    <CButton
                      className="rounded-pill"
                      onClick={() => editUser(user)}
                      color="primary"
                    >
                      View
                    </CButton>
                  </td>
                  <td>
                    <CButton
                      className="rounded-pill"
                      disabled={true}
                      onClick={() => blockUser(user._id)}
                      color="danger"
                    >
                      Block
                    </CButton>
                  </td>
                </tr>
              );
            })}
        </tbody>
      }
      {loading && (
        <tbody>
          <tr>
            <CSpinner color="primary" />
            loading....
          </tr>
        </tbody>
      )}
    </table>
  );
}

export default ListUsers;
