import React, { useState } from "react";
import { CFormInput, CButton, CSpinner } from "@coreui/react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/axiosInstance";

function EditAddressForm({
  isNewAddress,
  data,
  setData,
  address,
  setUserAddress,
  setVisible,
}) {
  const [loading, setLoading] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (
      !address.nickname ||
      !address.streetAddress ||
      !address.apartment ||
      !address.city ||
      !address.province ||
      !address.country ||
      //  !address.postalcode ||
      // !address.latitude ||
      // !address.longitude
      !address.cordinates
    )
      return toast.error("please enter values in every fields");
    if (data.address.find((add) => add._id === address._id) === address) return;

    setLoading(true);

    const instance = await axiosInstance();
    const { _id, cordinates, availableServiceLocations, ...addressWithOutId } =
      address;
    const payload = {
      ...addressWithOutId,
      latitude: cordinates[0],
      longitude: cordinates[1],
    };

    console.log(payload);
    const id = isNewAddress ? { uid: data.uid } : { addressId: _id }; // isNewAddress is a booleen it get true when the new address button is clicked;
    const queryUrl = isNewAddress
      ? `profile/address/add`
      : `/profile/address/edit`;

    try {
      const { data: newData } = await instance.post(queryUrl, {
        ...id,
        address: payload,
      });
      setData((preState) =>
        preState.map((user) => (user.uid === data.uid ? newData.data : user))
      );
      toast.success(
        isNewAddress ? "New Address Added" : "User address updated"
      );
      setVisible(false);
      setLoading(false);
    } catch (err) {
      toast.warning(err.response.data.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submitForm}>
      <div className="inputRow dflex m-1">
        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, nickname: e.target.value };
            })
          }
          value={address.nickname}
          placeholder="Nickname"
        />

        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, streetAddress: e.target.value };
            })
          }
          value={address.streetAddress}
          placeholder="Street Address"
        />
      </div>

      <div className="inputRow dflex m-1">
        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, apartment: e.target.value };
            })
          }
          value={address.apartment}
          placeholder="Apartment"
        />

        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, city: e.target.value };
            })
          }
          value={address.city}
          placeholder="City"
        />
      </div>

      <div className="inputRow dflex m-1">
        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, province: e.target.value };
            })
          }
          value={address.province}
          placeholder="Province"
        />

        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, country: e.target.value };
            })
          }
          value={address?.country}
          placeholder="Country"
        />
      </div>

      <div className="inputRow dflex m-1">
        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) => {
            const newLatitude = parseFloat(e.target.value);
            setUserAddress((preState) => ({
              ...preState,
              cordinates: [newLatitude, preState.cordinates[1]],
            }));
          }}
          value={address.cordinates && address.cordinates[0]}
          placeholder="latitude"
        />

        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) => {
            const newLongitude = parseFloat(e.target.value);
            setUserAddress((preState) => ({
              ...preState,
              cordinates: [preState.cordinates[0], newLongitude],
            }));
          }}
          value={address.cordinates && address.cordinates[1]}
          placeholder="longitude"
        />
        <CFormInput
          type="text"
          className="m-1"
          onChange={(e) =>
            setUserAddress((preState) => {
              return { ...preState, postalcode: e.target.value };
            })
          }
          value={address?.postalcode}
          placeholder="postalcode"
        />
      </div>

      <CButton
        className="submitButton"
        color="primary"
        type="submit"
        disabled={loading}
      >
        {loading ? (
          <CSpinner color="light" />
        ) : isNewAddress ? (
          `Add Address`
        ) : (
          `Save Changes`
        )}
      </CButton>
    </form>
  );
}

export default EditAddressForm;
