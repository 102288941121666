import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import EditVanDetailsModal from "../../../components/admin/Vans/EditVanDetails";

function EditVan({ visible, setVisible, data, refetch }) {
  return (
    <CModal
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>Edit Van</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <EditVanDetailsModal
          toggleModal={setVisible}
          data={data}
          refetch={refetch}
        />
      </CModalBody>
    </CModal>
  );
}

export default EditVan;
