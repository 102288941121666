import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppHeader from "../../components/coreui/AppHeader";
import AppSidebar from "../../components/coreui/AppSidebar";
import "../../scss/style.scss";
import firebase from "../../config/firebase";
// import { useDispatch } from "react-redux";
// import { set_user } from "../../Redux/profile/profileSlice";
import { axiosInstance } from "../../utils/axiosInstance";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";

function AdminDashLayout() {
  const [sidebarShow, setSidebarShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // const dispatch = useDispatch();
  let navigate = useNavigate();

  firebase.auth().onAuthStateChanged(async (user) => {
    const instance = await axiosInstance();
    console.log("auth changed");
    // console.log(user);
    if (user) {
      // dispatch(set_user(user));
      instance
        .post("/profile/checkUser", {
          phoneNumber: user.phoneNumber, 
          role: "admin",
        })
        .then((res) => {
          if (res.data.status) {
            // setIsAdmin(true)
            setIsLoading(false);
          }
        });
    } else {
      // dispatch(set_user({}));
      navigate({ pathname: "/signin" });
    }
  });
  return !isLoading ? (
    <div>
      <AppSidebar sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
}

export default AdminDashLayout;
