import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import { axiosInstance } from "../../../utils/axiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
// const AllMonths = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
function ListTimeSlotTable({ data, handleEditButtonClick, refetch }) {
  const [monthlyTimeSlot, setMonthlyTimeSlot] = useState([]); // eslint-disable-line
  const [date, setDate] = useState({
    // eslint-disable-line
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    day: new Date().getDate(),
  });
  const timezone = useSelector((state) => state.timezone);
  // const [isAvailable, setIsAvailable] = useState(null)

  // const [numberOFDays, setNumberOfDays] = useState(
  //   new Date(date.year, date.month, 0).getDate()
  // );

  useEffect(() => {
    const x = data?.data?.data?.filter(
      (singleDate) => new Date(singleDate.createdAt).getMonth() === date.month
    );
    setMonthlyTimeSlot(x);
  }, [date, data?.data?.data]);

  const toastId = useRef(null);

  const changeStatus = async (data) => {
    try {
      const instance = await axiosInstance();
      const { data: resultData } = await instance.post("/timeslot/edit", {
        timeslotId: data.timeslotId,
        isAvailable: !data.isAvailable,
        startTime: moment(data.startTime),
        endTime: moment(data.endTime),
      });
      if (resultData.status) {
        refetch();
        toast.success("Status changed");
        console.log(resultData);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      return toast.update(toastId.current, {
        render: error?.response?.data?.message || "something went wrong",
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        isLoading: false,
      });
    }
  };

  const deleteTimeslot = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toastId.current = toast("Deleting Timeslot", {
          autoClose: false,
          isLoading: true,
        });
        const instance = await axiosInstance();
        await instance
          .post("/timeslot/delete", { timeslotId: id })
          .then((res) => {
            console.log(res.data.status);
            if (res?.data?.status) {
              refetch();
              toast.dismiss(toastId.current);
              toast.success("Timeslot Deleted", {
                // type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            console.log(data);
            console.log(error.response);
            return toast.update(toastId.current, {
              render: error?.response?.data?.message || "something went wrong",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              isLoading: false,
            });
          });
      }
    });
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sl.no</th>
            <th scope="col" style={{ width: "13%" }}>
              day
            </th>
            <th scope="col">Start Time</th>
            <th scope="col">End Time</th>
            <th scope="col"> Is Available </th>
            <th scope="col"> Edit </th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data.map((timeSlot, i) => (
            <tr key={timeSlot.timeslotId}>
              <th>{i + 1}</th>

              <td>
                {" "}
                {moment
                  .tz(timeSlot.startTime, timezone)
                  .format("MMM DD, YYYY ")}
              </td>

              <td>
                {moment.tz(timeSlot.startTime, timezone).format("hh:mm a")}
              </td>
              <td>{moment.tz(timeSlot.endTime, timezone).format("hh:mm a")}</td>
              <td style={{ width: "13%" }}>
                <button
                  title="Click to change the status"
                  className={
                    timeSlot.isAvailable
                      ? "btn-primary btn  rounded-pill text-white   changeStatusBtn"
                      : " btn-secondary btn  rounded-pill text-white changeStatusBtn"
                  }
                  type="button"
                  onClick={() => changeStatus(timeSlot)}
                  // disabled={loading === timeSlot.timeslotId}
                >
                  {timeSlot.isAvailable ? `Avaliable` : `Not Avaliable`}
                </button>
              </td>

              <td style={{ width: "13%" }}>
                <button
                  style={{ width: "6rem" }}
                  className="btn btn-warning rounded-pill text-white "
                  type="button"
                  onClick={() => handleEditButtonClick(timeSlot)}
                >
                  Edit
                </button>
              </td>

              <td style={{ width: "13%" }}>
                <button
                  class="btn btn-danger rounded-pill text-white"
                  type="button"
                  onClick={() => deleteTimeslot(timeSlot.timeslotId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <div className="calendar-month">
        <section className="calendar-month-header">
          <div
            id="selected-month"
            className="calendar-month-header-selected-month"
          >
            {date.day} {AllMonths[date.month - 1]} {date.year}
          </div>

          <div className="calendar-month-header-selectors">
            <span id="present-month-selector">
              select date
              <input
                type="date"
                className="dateSelector"
                value={date}
                onChange={(e) => {
                  setDate({
                    month: new Date(e.target.value).getMonth() + 1,
                    year: new Date(e.target.value).getFullYear(),
                    day: new Date(e.target.value).getDate(),
                  });
                  setNumberOfDays(
                    new Date(
                      new Date(e.target.value).getFullYear(),
                      new Date(e.target.value).getMonth() + 1,
                      0
                    ).getDate()
                  );
                }}
              />
            </span>
          </div>
        </section>
        <ol id="days-of-week" className="day-of-week">
          <li>Mon</li>
          <li>Tue</li>
          <li>Wed</li>
          <li>Thu</li>
          <li>Fri</li>
          <li>Sat</li>
          <li>Sun</li>
        </ol>

        <ol id="calendar-days" className="days-grid">
          {[...Array(numberOFDays)].map((x, i) => (
            <li
              key={i}
              onClick={() => {
                const arr = [];
                monthlyTimeSlot?.map((iter, count) =>
                  new Date(iter.createdAt).getDate() === i + 1
                    ? arr.push(iter)
                    : null
                );
                arr.length && setTimeSlot(arr);
                arr.length && setVisible(true);
              }}
              className={
                monthlyTimeSlot?.some(
                  (iter) => new Date(iter.createdAt).getDate() === i + 1
                )
                  ? "calendar-day bg_grey"
                  : new Date(`${date.year}-${date.month}-${i + 1}`) <=
                    new Date()
                  ? "calendar-day cursur_disable"
                  : new Date().getDate() === i + 1
                  ? "calendar-day currentDay"
                  : "calendar-day ````````````````````upCommingDay````````````````````"
              }
            >
              <span>
                <div className="small">
                  {monthlyTimeSlot?.some(
                    (iter) => new Date(iter.createdAt).getDate() === i + 1
                  )
                    ? "✅"
                    : new Date(`${date.year}-${date.month}-${i + 1}`) <=
                      new Date()
                    ? "❌"
                    : "⬆️"}
                </div>
                <b>{i + 1}</b>
              </span>
            </li>
          ))}
        </ol>
      </div> */}
    </>
  );
}

export default ListTimeSlotTable;
