import axios from "axios";
import { getIdTokens } from ".";
import { KIVLIK_API_URL } from "../config";


// Function to get axios instance with configurations
const axiosInstance = async () => {
  const token = await getIdTokens();

  return axios.create({
    baseURL: KIVLIK_API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export { axiosInstance };
