import {
  Area,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const PriceChart = ({ data }) => {
  const paymentDetails = Object.values(
    data.reduce((result, item) => {
      const date = item.createdAt.split("T")[0];
      if (result[date]) {
        result[date].amount += item.totalBookingAmountInCents;
      } else {
        result[date] = {
          date: date,
          Amount: item.totalBookingAmountInCents / 100,
        };
      }
      return result;
    }, {})
  );

  return (
    <div className="w-100 mt-3" style={{ height: 330 }}>
      <ResponsiveContainer>
        <AreaChart data={paymentDetails}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Amount"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PriceChart;
