import React, { useState } from "react";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea,
  CButton,
  CFormSelect,
} from "@coreui/react";
import SelectBodytype from "./SelectBodytype";
import { useSelector, useDispatch } from "react-redux";
import {
  reset_service,
  set_description,
  set_serviceName,
  set_type,
} from "../../../../Redux/service/serviceSlice";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router";

function CreateServiceForm() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const service = useSelector((state) => state.service);
  const [validated, setValidated] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { serviceName, description, type, bodySpecificDetails } = service;

      let bodySpecificStructuredArray = []; // array for storint formated bodySpecificDetails for server
      for (let i = 0; i < bodySpecificDetails.length; i++) {
        const { _id, isActive, price, salePrice, duration } =
          bodySpecificDetails[i];

        if (isActive) {
          console.log({price, salePrice, duration})
          console.log("pushing ", i);
          bodySpecificStructuredArray.push({
            bodytypeId: _id,
            priceInCents: parseInt(price) * 100,
            salePriceInCents: parseInt(salePrice) * 100,
            durationInMins: parseInt(duration),
          });
        }
      }
      //TODO: post below data to server
      console.log({
        title: serviceName,
        description,
        type,
        bodySpecificStructuredArray,
      });

      const instance = await axiosInstance();
      instance
        .post("/package/service/add", {
          title: serviceName,
          description: description,
          type: type,
          bodySpecificData: bodySpecificStructuredArray,
        })
        .then((res) => {
          if (res.data.status) {
            toast.success("Service is Created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            dispatch(reset_service())
            navigate({pathname:"/admin/services/list"})
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          if (!err.response.data.status) {
            toast.error(err.response.data.message || "something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
    setValidated(true);
  };

  return (
    <div>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3">
          <CFormLabel htmlFor="serviceName">Service Name</CFormLabel>
          <CFormInput
            type="text"
            id="serviceName"
            placeholder="Enter Service Name here"
            required
            value={service.serviceName}
            onChange={(e) => dispatch(set_serviceName(e.target.value))}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="description">Description</CFormLabel>
          <CFormTextarea
            id="description"
            required
            rows="3"
            value={service.description}
            onChange={(e) => dispatch(set_description(e.target.value))}
          ></CFormTextarea>
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="description">Service Type</CFormLabel>
          <CFormSelect
            size="lg"
            className="mb-3"
            aria-label="Large select example"
            value={service.type}
            onChange={(e) => dispatch(set_type(e.target.value))}
            name="type"
          >
            <option selected value="interior">
              Interior
            </option>
            <option value="exterior">Exterior</option>
          </CFormSelect>
        </div>
        <div className="mb-3  ">
          <CFormLabel>Body Specfic Details</CFormLabel>
          <SelectBodytype />
        </div>
        <CButton color="primary" type="submit">
          Create Service
        </CButton>
      </CForm>
    </div>
  );
}

export default CreateServiceForm;
