import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { axiosInstance } from "../../../utils/axiosInstance";
import ListUsers from "../../../components/admin/Users/ListUsers";
import ShowDetails from "./ShowDetails";
import Swal from "sweetalert2";

const ROLE_ARRAY = ["All Users", "customer", "executive"];

function ListUser({userRole}) {
  const [visible, setVisible] = useState(false);
  const [eidtData, setEditData] = useState({});
  const [showUser, setshowUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("All Users");

  useEffect(() => {
    if(userRole) setRole(userRole);
  }, [userRole]);

  useEffect(() => {
    // IIFE to fetch adn filter data from server

    // for the  cancellation of the axios call
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      setLoading(true);
      const urlQurey = role === "All Users" ? "" : `?role=${role}`;
      try {
        const instance = await axiosInstance();
        const { data } = await instance.get(`/profile/all${urlQurey}`, {
          signal,
        });
        setshowUser(data.data);
        setLoading(false);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).finally(() => window.location.reload);
      }
    })();

    return () => controller.abort();
  }, [role]);

  const editUser = (data) => {
    setEditData(data);
    setVisible(true);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={10}>
              <h4 className="card-title mb-0">KIVLIK</h4>
              <div className="small text-medium-emphasis mb-2">UserList</div>
            </CCol>
            {/* --------------- */}
            <CCol>
              <CDropdown>
                <CDropdownToggle color="primary">{role}</CDropdownToggle>
                <CDropdownMenu>
                  {ROLE_ARRAY.map((role) => (
                    <CDropdownItem key={role} onClick={() => setRole(role)}>
                      {role}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            </CCol>
            {/* -------------- */}
          </CRow>
          <CRow>
            <CCol className="overFlowX">
              <ListUsers
                editUser={editUser}
                data={showUser}
                loading={loading}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <ShowDetails
          setVisible={setVisible}
          visible={visible}
          data={eidtData}
          setData={setshowUser}
        />
      </CCard>
    </>
  );
}

export default ListUser;
