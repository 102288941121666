import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import EditBodytypeForm from "../../../components/admin/bodtypes/EditBodytypeForm/EditBodytypeForm";

function EditBodytype({ data, setVisible, visible }) {
  return (
    <CModal
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>Edit {data.bodytypeName} </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <EditBodytypeForm toggleModal={setVisible} data={data} />
      </CModalBody>
    </CModal>
  );
}

export default EditBodytype;
