import React from "react";
import { useQuery } from "react-query";
import { getAllBodytype } from "../../../../utils/query/bodytypeQuery";
import { CSpinner } from "@coreui/react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function ListBodytypeTable({ editBodytype }) {
  const { data, isLoading, status, isFetched, isError } = useQuery(
    "getAllBodytype",
    getAllBodytype
  );
  if (isFetched) {
    console.log(data);
  }
  console.log({ data, isLoading, status, isFetched, isError });
  const timezone = useSelector((state) => state.timezone);
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Sl.no</th>
          <th scope="col">Bodytype Name</th>
          <th scope="col">Image</th>
          <th scope="col">Created At</th>
          <th scope="col">Edited At</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      {status === "success" && (
        <tbody>
          {data?.data?.data.map(
            ({ bodytypeName, createdAt, updatedAt, image, _id }, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{bodytypeName}</td>
                  <td>
                    <img
                      alt="bodytype"
                      style={{ width: "100px", height: "70px" }}
                      src={image}
                    />
                  </td>
                  <td>
                    {moment.tz(createdAt, timezone).format("DD MMM h:mm a")}
                  </td>
                  <td>
                    {moment.tz(updatedAt, timezone).format("DD MMM h:mm a")}
                  </td>
                  <td>
                    <button
                      className="btn btn-warning rounded-pill"
                      onClick={() => editBodytype(data?.data?.data[i])}
                      type="button"
                    >
                      Edit{" "}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger rounded-pill"
                      type="button"
                      disabled
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      )}
      {status === "loading" && (
        <tbody>
          <CSpinner color="primary" /> loading....
        </tbody>
      )}
    </table>
  );
}

export default ListBodytypeTable;
