import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import ViewVechile from "../../../components/admin/Bookings/DetailsOfVechile";

function DetailsOfVechiles({ visible, setVisible, data }) {
     return (
          <CModal
               size="xl"
               visible={visible}
               onClose={() => setVisible(preProps => { return { ...preProps, vechile: false } })}
               backdrop="static"
          >
               <CModalHeader>
                    <CModalTitle>Edit  Service</CModalTitle>
               </CModalHeader>
               <CModalBody>
                    <ViewVechile toggleModal={setVisible} data={data} />
               </CModalBody>
          </CModal>
     );
}

export default DetailsOfVechiles;