import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

const SortableServiceTable = ({ data, i, refetch, editService }) => {
  const { title, type, createdAt, updatedAt, _id } = data;
  const timezone = useSelector((state) => state.timezone);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: data });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteService = (serviceId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const instance = await axiosInstance();
        instance
          .post(`/package/service/delete`, {
            serviceId,
          })
          .then((res) => {
            refetch();
            if (res.data.status) {
              Swal.fire(
                "Deleted!",
                "Your service has been deleted.",
                "success"
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <th {...attributes} {...listeners} scope="row">
        {i + 1}
      </th>
      <td {...attributes} {...listeners}>
        {title}
      </td>
      <td {...attributes} {...listeners}>
        {type}
      </td>
      <td {...attributes} {...listeners}>
        {moment.tz(createdAt, timezone).format("DD MMM h:mm a")}
      </td>
      <td {...attributes} {...listeners}>
        {moment.tz(updatedAt, timezone).format("DD MMM h:mm a")}
      </td>
      <td>
        <button
          className="btn btn-warning rounded-pill"
          onClick={() => editService(data)}
          type="button"
        >
          Edit
        </button>
      </td>
      <td>
        <button
          className="btn btn-danger rounded-pill"
          type="button"
          onClick={() => {
            deleteService(_id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default SortableServiceTable;
