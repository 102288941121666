import React, { useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import ListBookingTable from "../../../components/admin/Bookings/ListBookingTable";
import DetailsOfVehicle from "./DetailsofVechiles";
import AddressDetails from "./AddressDetails";
import PackageDetails from "./PackageDetails";
import ViewBooking from "./ViewBooking";

function ListBookings() {

     const initialState = { vehicle: false, package: false, address: false, booking: false }
     const [vehicle, setVehicle] = useState(null);
     const [address, setAddress] = useState(null);
     const [washPackage, setPackage] = useState(null)
     const [bookingData, setBookingData] = useState(null)
     const [visiblity, setVisiblity] = useState(initialState)

     return (
          <>
               <CCard className="mb-4">
                    <CCardBody>
                         <CRow>
                              <CCol sm={5}>
                                   <h4 id="traffic" className="card-title mb-0">
                                        KIVLIK
                                   </h4>
                                   <div className="small text-medium-emhpasis"> Bookings List </div>
                              </CCol>
                         </CRow>
                         <CRow>
                              <CCol>
                                   <ListBookingTable 
                                        setPackage={setPackage}
                                        setAddress={setAddress}
                                        setVehicle={setVehicle}
                                        setBookingData={setBookingData}
                                        setVisible={setVisiblity} 
                                   />
                              </CCol>
                         </CRow>
                    </CCardBody>
               </CCard>
               <ViewBooking data={bookingData} visible={visiblity.booking} setVisible={setVisiblity} />
               <DetailsOfVehicle data={vehicle} visible={visiblity.vehicle} setVisible={setVisiblity} />
               <AddressDetails data={address} visible={visiblity.address} setVisible={setVisiblity} />
               <PackageDetails data={washPackage} visible={visiblity.package} setVisible={setVisiblity} />

          </>
     )
}

export default ListBookings