import { configureStore } from "@reduxjs/toolkit";
import profileSlice from "./profile/profileSlice";
import createPackageSlice from "./package/createPackageSlice";
import serviceSlice from "./service/serviceSlice";
import editServiceSlice from "./service/editServiceSlice";
import editPackageSlice from "./package/editPackageSlice";
import userSlice from "./user/userSlice";
import timezoneSlice from "./timezone/timezoneSlice";

export default configureStore({
  reducer: {
    profile: profileSlice,
    createPackage: createPackageSlice,
    editPackage: editPackageSlice,
    service: serviceSlice,
    editService: editServiceSlice,
    user: userSlice,
    timezone: timezoneSlice,
  },
});
