import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CSpinner } from "@coreui/react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { getAllPackages } from "../../../../utils/query/packageQuery";
import SortablePackageTable from "./SortablePackageTable";

function ListPackageTable({
  editService,
  edited,
  setSortedPackages,
  setShowSaveBtn,
}) {
  const toastId = useRef(null);
  const notifyToast = (message) =>
    (toastId.current = toast(message, { autoClose: false, isLoading: true }));
  const updateToast = (message, type) =>
    toast.update(toastId.current, {
      render: message,
      type: toast.TYPE[type],
      autoClose: 5000,
      isLoading: false,
    });

  const { data, isLoading, status, isFetched, isError, refetch } = useQuery(
    "getPackages",
    getAllPackages
  );
  if (isFetched) {
  }

  const [packageList, setPackageList] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [updatedList, setUpdatedList] = useState(null);

  useEffect(() => {
    if (!isDragging) setPackageList(data?.data?.data);

    if (updatedList) {
      setSortedPackages([]);
      updatedList.map((packageItem, i) => {
        setSortedPackages((prev) => [
          ...prev,
          {
            packageId: packageItem._id,
            sequenceNumeric: i + 1,
          },
        ]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDragging, updatedList]);

  useEffect(() => {
    if (edited) {
      setIsDragging(false);
      notifyToast("Packages is refetching!");
      refetch()
        .then(() => {
          updateToast("Packages Refetched sucessfully", "SUCCESS");
        })
        .catch(() => {
          updateToast("Packages Refetching failed", "ERROR");
        });
    }
  }, [edited]);

  const handleDragEnd = (event) => {
    setIsDragging(true);
    const { active, over } = event;
    if (active.id !== over.id) {
      setShowSaveBtn(true);
      setPackageList((items) => {
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        setUpdatedList(arrayMove(items, activeIndex, overIndex));
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  if (!packageList) {
    return (
      <tbody>
        <CSpinner color="primary" />
        loading packages....
      </tbody>
    );
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sl.no</th>
            <th scope="col">Package Name</th>
            <th scope="col">Description</th>
            <th scope="col">Created At</th>
            <th scope="col">Edited At</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody>
            <SortableContext
              items={packageList}
              strategy={verticalListSortingStrategy}
            >
              {packageList.map((packageItem, i) => (
                <SortablePackageTable
                  key={packageItem._id}
                  i={i}
                  packageItem={packageItem}
                  refetch={refetch}
                  editService={editService}
                />
              ))}
            </SortableContext>
          </tbody>
        )}
      </table>
    </DndContext>
  );
}

export default ListPackageTable;
