import React, { useRef } from "react";
import { CButton, CSpinner } from "@coreui/react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/axiosInstance";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function ListVansTable({ editVanData, data, status, refetch }) {
  const toastId = useRef(null);
  const timezone = useSelector((state) => state.timezone);

  const deleteVan = async (data) => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (isConfirmed) {
      toastId.current = toast(`Deleting van -  ${data.kNumber}`, {
        autoClose: false,
        isLoading: true,
      });
      const instance = await axiosInstance();
      try {
        const { data: resultData } = await instance.post("/van/delete", {
          vanId: data._id,
        });
        if (resultData.status) {
          refetch();
          toast.update(toastId.current, {
            render: "Van deleted Succesfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
            isLoading: false,
          });
        }
      } catch (error) {
        refetch();
        console.log(error.response);
        return toast.update(toastId.current, {
          render: error?.response?.data?.message || "something went wrong",
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          isLoading: false,
        });
      }
    }
  };

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Sl.no</th>
          <th scope="col">K-Number</th>
          <th scope="col">City</th>
          <th scope="col">Number Plate</th>
          <th scope="col">Created At</th>
          <th scope="col">QR code generation</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      {status === "success" && (
        <tbody>
          {data?.data?.data
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map(({ _id, kNumber, city, numberPlate, createdAt }, i) => {
              return (
                <tr key={_id}>
                  <td>{i + 1}</td>
                  <td> {kNumber}</td>
                  <td> {city}</td>
                  <td> {numberPlate}</td>
                  <td>
                    {" "}
                    {moment.tz(createdAt, timezone).format("DD MMM h:mm a")}
                  </td>

                  {/* edit  tde vans  */}
                  <td>
                    <CButton
                      onClick={async () => {
                        const instance = await axiosInstance();
                        instance
                          .get("/van/generate-qr?vanId=" + _id)
                          .then((res) => {
                            const link = document.createElement("a");
                            link.href = res.data;
                            link.download = kNumber + ".png";
                            link.click();
                          });
                      }}
                      color="info"
                      className="rounded-pill"
                    >
                      Generate QR
                    </CButton>
                  </td>
                  <td>
                    <CButton
                      onClick={() => editVanData(data?.data?.data[i])}
                      color="warning"
                      className="rounded-pill"
                    >
                      Edit
                    </CButton>
                  </td>
                  <td>
                    <CButton
                      disabled={true}
                      onClick={() => deleteVan(_id)}
                      color="danger"
                      className="rounded-pill"
                    >
                      Delete
                    </CButton>
                  </td>
                </tr>
              );
            })}
        </tbody>
      )}
      {status === "loading" && (
        <tbody>
          <CSpinner color="primary" />
          loading....
        </tbody>
      )}
    </table>
  );
}

export default ListVansTable;
