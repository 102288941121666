import { CSpinner } from "@coreui/react";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useQuery } from "react-query";
import { getAllServices } from "../../../../utils/query/serviceQuery";
import SortableServiceTable from "./SortableServiceTable";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

function ListServiceTable({ editService, setSortedServices, setShowSaveBtn }) {
  const { data, isLoading, status, isFetched, isError, refetch } = useQuery(
    "getServices",
    getAllServices
  );

  const [servicesList, setServicesList] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [updatedList, setUpdatedList] = useState(null);

  useEffect(() => {
    if (!dragging) setServicesList(data?.data?.data);
    console.log(isFetched);

    if (updatedList) {
      setSortedServices([]);
      updatedList.map((service, i) => {
        setSortedServices((prev) => [
          ...prev,
          {
            serviceId: service._id,
            sequenceNumeric: i + 1,
          },
        ]);
      });
    }
  }, [data, dragging, updatedList]);

  const handleDragEnd = (event) => {
    setDragging(true);
    const { active, over } = event;
    if (active.id !== over.id) {
      setShowSaveBtn(true);
      setServicesList((items) => {
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        setUpdatedList(arrayMove(items, activeIndex, overIndex));
        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  if (!servicesList) {
    return (
      <tbody>
        <CSpinner color="primary" />
        loading....
      </tbody>
    );
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sl.no</th>
            <th scope="col">Service Name</th>
            <th scope="col">Type</th>
            <th scope="col">Created At</th>
            <th scope="col">Edited At</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        {status === "success" && (
          <tbody>
            <SortableContext
              items={servicesList}
              strategy={verticalListSortingStrategy}
            >
              {servicesList.map((service, i) => (
                <SortableServiceTable
                  key={service._id}
                  data={service}
                  i={i}
                  editService={editService}
                  refetch={refetch}
                />
              ))}
            </SortableContext>
          </tbody>
        )}
      </table>
    </DndContext>
  );
}

export default ListServiceTable;
