import { Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout/MainLayout";
import LandingPage from "./pages/LandingPage/LandingPage";
import AdminLayout from "./layout/AdminDash/AdminDashLayout";
import AdminDashboard from "./pages/Admin/Dashboard/AdminDashboard";

import SignInPage from "./pages/SignIn/SignInPage";
import SignUpPage from "./pages/SignUp/SignUpPage";
import CreateService from "./pages/Admin/Services/CreateService";
import ListServices from "./pages/Admin/Services/ListServices";
import CreatePackage from "./pages/Admin/Packages/CreatePackage";
import ListPackages from "./pages/Admin/Packages/ListPackages";
import ListBodytype from "./pages/Admin/Bodytype/ListBodytype";
import CreateBodytype from "./pages/Admin/Bodytype/CreateBodytype";
import ListTimeslots from "./pages/Admin/Timeslot/ListTimeslots";
import CreateTimeslot from "./pages/Admin/Timeslot/CreateTimeslot";
import CreateVans from "./pages/Admin/Vans/CreateVan";
import ListVans from "./pages/Admin/Vans/ListVans";
import ListUser from "./pages/Admin/Users/ListUser";
import ListBookings from "./pages/Admin/Bookings/ListBookings";
import CreateUser from "./pages/Admin/Users/CreateUser";
import TimeslotCalenderView from "./pages/Admin/Timeslot/TimeslotCalenderView";

function KivlikRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Route>

      {/* ADMIN Routes below */}
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<AdminDashboard />} />
        {/* Services Routes */}
        <Route path="/admin/services">
          <Route path="/admin/services/create" element={<CreateService />} />
          <Route path="/admin/services/list" element={<ListServices />} />
        </Route>
        {/* Packages Routes */}
        <Route path="/admin/packages">
          <Route path="/admin/packages/create" element={<CreatePackage />} />
          <Route path="/admin/packages/list" element={<ListPackages />} />
        </Route>
        {/* Bodytype Routes */}
        <Route path="/admin/bodytypes">
          <Route path="/admin/bodytypes/create" element={<CreateBodytype />} />
          <Route path="/admin/bodytypes/list" element={<ListBodytype />} />
        </Route>
        {/* Timeslot Routes */}
        <Route path="/admin/timeslots">
          <Route path="/admin/timeslots/create" element={<CreateTimeslot />} />
          <Route path="/admin/timeslots/list" element={<ListTimeslots />} />
          <Route path="/admin/timeslots/calender" element={<TimeslotCalenderView />} />
        </Route>

        {/* Vans Routes */}

        <Route path="/admin/vans">
          <Route path="/admin/vans/create" element={<CreateVans />} />
          <Route path="/admin/vans/list" element={<ListVans />} />
        </Route>
        {/* ---------- */}
        {/* User Routes */}
        <Route path="/admin/user">
          <Route
            path="/admin/user/list"
            element={<ListUser userRole="All Users" />}
          />
          <Route
            path="/admin/user/executive"
            element={<ListUser userRole="executive" />}
          />
          <Route path="/admin/user/create" element={<CreateUser />} />
        </Route>
        {/* ----------- */}
        {/* Booking  */}
        <Route path="/admin/bookings">
          <Route path="/admin/bookings/list" element={<ListBookings />} />
          {/* <Route path="/admin/vans/list" element={<ListVans />} /> */}
        </Route>
        {/* -------------- */}
      </Route>
      {/* Subscription */}
      {/* <Route path="/admin/subscription">
        <Route path="/admin/subscription/calender" element={<ViewSchedule />} />
      </Route> */}
      {/* --------------- */}
      <Route path="*" element={<>NOT FOUND</>} />
    </Routes>
  );
}

export default KivlikRoutes;
