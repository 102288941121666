import React from 'react'

function SignUpPage() {
    return (
        <div>
            <h1>SIGN UP PAGE</h1>
        </div>
    )
}

export default SignUpPage
