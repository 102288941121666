import { CForm, CFormLabel, CButton, CFormInput } from "@coreui/react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../utils/axiosInstance";

function DayCount({ timeSlotData, setTimeSloteData, isDisable, fetchData }) {
  const updateDayCount = async () => {
    const toastId = toast.loading("Saving changes...", {
      autoClose: false,
      isLoading: true,
    });
    try {
      //handling promise
      const instance = await axiosInstance();
      await instance.post("/timeslot/config/update-timeslot-daycount", {
        count: timeSlotData.timeslotsDayCount,
      });
      toast.update(toastId, {
        render: "Changes Saved !",
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      fetchData();
    } catch (err) {
      toast.update(toastId, {
        render: err.message || "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };
  return (
    <div className="dayCountOuter">
      {/* <p>{JSON.stringify(timeSlotData)}</p> */}
      <CForm>
        <CFormLabel>Day Count</CFormLabel>
        <CFormInput
          className="mb-3"
          type="number"
          placeholder="Day count of the timeslot template"
          value={timeSlotData.timeslotsDayCount}
          disabled={isDisable}
          onChange={(e) => {
            if (e.target.value.length > 2) return false;
            setTimeSloteData((preState) => {
              return { ...preState, timeslotsDayCount: e.target.value };
            });
          }}
        />

        <CButton
          disabled={isDisable}
          className="submitButton"
          onClick={updateDayCount}
        >
          Save changes
        </CButton>
      </CForm>
    </div>
  );
}

export default DayCount;
