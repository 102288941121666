import React, { useEffect, useState } from "react";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CFormSwitch,
  CFormInput,
  CSpinner,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { set_bodySpecificDetails } from "../../../../Redux/service/editServiceSlice";
import { useQuery } from "react-query";
import { getAllBodytype } from "../../../../utils/query/bodytypeQuery";

function SelectBodytype({ oldBodySpecific }) {
  const [tableData, setTableData] = useState([]);
  const [toggleAll, setToggleAll] = useState(true);
  const [focused, setFocused] = useState({ _id: "", name: "" });
  const { data, status } = useQuery("getAllBodytype", getAllBodytype);
  console.log({ data, status });

  useEffect(() => {
    console.log("oldBodySpecific", oldBodySpecific);
    if (data) {
      console.log("asdasdasdasd");
      const newTableData = data.data.data.map((obj) => {
        const matchedDetails = oldBodySpecific.find(
          (x) => x.bodytypeId === obj._id
        );
        if (matchedDetails) {
          console.log("matched");

          return {
            _id: matchedDetails.bodytypeId,
            isActive: true,
            bodytypeName: obj.bodytypeName,
            price: matchedDetails.priceInCents / 100,
            salePrice: matchedDetails.salePriceInCents / 100 || 1,
            duration: matchedDetails.durationInMins,
          };
        } else {
          return { ...obj, isActive: false };
        }
      });
      console.log(newTableData);
      setTableData(newTableData);
      dispatch(set_bodySpecificDetails(newTableData));
    }
    // eslint-disable-next-line
  }, [oldBodySpecific, data]);

  // useEffect(() => {
  //   if (data) {
  //     setTableData(
  //       data.data.data.map((x) => {
  //         return { ...x, isActive: x.isActive===false ?  false :true };
  //       })
  //     );
  //   }
  // }, [data]);

  const dispatch = useDispatch();

  const handleChange = (e, _id) => {
    const newTableData = tableData.map((x) => {
      if (x._id === _id) {
        return { ...x, [e.target.name]: e.target.value };
      }
      return x;
    });
    setTableData(newTableData);
    dispatch(set_bodySpecificDetails(newTableData));
    setFocused({ _id, name: e.target.name });
  };

  const toggleActive = (_id) => {
    let newTableData = tableData.map((table) => {
      if (table._id === _id) {
        return { ...table, isActive: !table.isActive };
      }
      return table;
    });
    setTableData(newTableData);
    dispatch(set_bodySpecificDetails(newTableData));
  };

  const toggleAllActive = () => {
    const newTableData = tableData.map((x) => {
      return { ...x, isActive: !toggleAll };
    });
    setToggleAll(!toggleAll);
    setTableData(newTableData);
    dispatch(set_bodySpecificDetails(newTableData));
  };

  return (
    <>
      <CTable hover>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            <CTableHeaderCell scope="col">
              {" "}
              <CFormSwitch
                label="select all"
                onClick={toggleAllActive}
                checked={toggleAll}
              />
            </CTableHeaderCell>
            <CTableHeaderCell scope="col">Bodytype</CTableHeaderCell>
            <CTableHeaderCell scope="col">Price ($)</CTableHeaderCell>
            <CTableHeaderCell scope="col">Sale Price ($)</CTableHeaderCell>
            <CTableHeaderCell scope="col">Duration (Mins)</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        {status === "success" && (
          <CTableBody>
            {tableData.map(
              (
                { bodytypeName, _id, isActive, price, salePrice, duration },
                i
              ) => {
                return (
                  <CTableRow key={i} color={isActive ? "" : "danger"}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>
                      <CFormSwitch
                        onChange={() => {
                          toggleActive(_id, isActive);
                        }}
                        checked={isActive}
                      />
                    </CTableDataCell>
                    <CTableDataCell>{bodytypeName}</CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === "price"
                          ) {
                            input.focus();
                          }
                        }}
                        type="number"
                        name="price"
                        placeholder={`Enter price for ${bodytypeName}`}
                        required
                        min="1"
                        disabled={!isActive}
                        value={price}
                        onChange={(e) => handleChange(e, _id)}
                      />
                    </CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === "salePrice"
                          ) {
                            input.focus();
                          }
                        }}
                        type="number"
                        name="salePrice"
                        placeholder={`Enter sale price for ${bodytypeName}`}
                        required
                        disabled={!isActive}
                        min="1"
                        value={salePrice}
                        onChange={(e) => handleChange(e, _id)}
                      />
                    </CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === "duration"
                          ) {
                            input.focus();
                          }
                        }}
                        type="number"
                        name="duration"
                        placeholder={`Enter duration for ${bodytypeName}`}
                        required
                        disabled={!isActive}
                        value={duration}
                        min="1"
                        onChange={(e) => handleChange(e, _id)}
                      />
                    </CTableDataCell>
                  </CTableRow>
                );
              }
            )}
          </CTableBody>
        )}
        {status === "loading" && (
          <CTableBody>
            <CSpinner color="primary" /> Fetching BodyTypes...
          </CTableBody>
        )}
      </CTable>
    </>
  );
}

export default SelectBodytype;
