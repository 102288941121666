import React, { useEffect, useRef, useState } from "react";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CFormSwitch,
  CFormInput,
  CSpinner,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { set_bodySpecificDetails } from "../../../../Redux/package/editPackageSlice";
import { useQuery } from "react-query";
import { getAllBodytype } from "../../../../utils/query/bodytypeQuery";
import { toast } from "react-toastify";

// const bodytypeData = [
//   {
//     _id: "1",
//     bodytypeName: "Sedan",
//     isActive: true,
//   },
//   {
//     _id: "2",
//     bodytypeName: "SUV (5 Seater)",
//     isActive: true,
//   },
//   {
//     _id: "3",
//     bodytypeName: "Sedan 2",
//     isActive: true,
//   },
//   {
//     _id: "4",
//     bodytypeName: "luxuary",
//     isActive: true,
//   },
// ];

function BodySpecificPriceAndDuration({ oldPackagePrices, oldDurations }) {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [focused, setFocused] = useState({ _id: "", name: "" });
  const [toggleAll, setToggleAll] = useState(false);
  const { data, status } = useQuery("getAllBodytype", getAllBodytype);
  const notifyToast = (message) =>
    (toastId.current = toast(message, { autoClose: false, isLoading: true }));
  const updateToast = () =>
    toast.update(toastId.current, {
      render: "Bodytypes Fetched sucessfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      isLoading: false,
    });
  useEffect(() => {
    if (data) {
      setTableData(
        data.data.data.map((x, i) => {
          const packagePrice = oldPackagePrices.find(
            (obj) => x._id === obj.bodytypeId
          );
          const duration = oldDurations.find((obj) => x._id === obj.bodytypeId);
          if (packagePrice && duration) {
            return {
              ...x,
              isActive: true,
              price: tableData[i]?.price
                ? tableData[i]?.price
                : packagePrice.priceInCents / 100,
              salePrice: tableData[i]?.salePrice
                ? tableData[i]?.salePrice
                : packagePrice.salePriceInCents / 100,
              duration: tableData[i]?.duration
                ? tableData[i]?.duration
                : duration.durationInMin,
            };
          }
          return {
            ...x,
            isActive: false,
            price: tableData[i]?.price ? tableData[i].price : "",
            salePrice: tableData[i]?.salePrice ? tableData[i].salePrice : "",
            duration: tableData[i]?.duration ? tableData[i].duration : "",
          };
        })
      );
    }
    //eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (status === "loading") {
      return notifyToast("Body Types are fetching..");
    }
    if (status === "success") {
      return updateToast();
    }
  }, [status]);

  for (let i = 0; (i < tableData.length) & !toggleAll; i++) {
    if (tableData[i].isActive) {
      setToggleAll(true);
      break;
    }
  }

  const handleChange = (e, _id) => {
    if (e.target.value === "" || /^\d+\.?\d*$/.test(e.target.value)) {
      const newTableData = tableData.map((x) => {
        if (x._id === _id) {
          return { ...x, [e.target.name]: e.target.value };
        }
        return x;
      });
      setTableData(newTableData);
      dispatch(set_bodySpecificDetails(newTableData));
      setFocused({ _id, name: e.target.name });
    }
  };

  const toggleActive = (_id, isActive) => {
    setToggleAll(false);
    console.log("toggleActive");

    let newTableData = tableData.map((table) => {
      if (table._id === _id) {
        // table.isActive = !table.isActive;
        return { ...table, isActive: !table.isActive };
      }
      return table;
    });
    setTableData(newTableData);
    dispatch(set_bodySpecificDetails(newTableData));
  };

  const toggleAllActive = () => {
    const newTableData = tableData.map((x) => {
      // x.isActive = !toggleAll;
      return { ...x, isActive: !toggleAll };
    });
    setToggleAll(!toggleAll);
    setTableData(newTableData);
    dispatch(set_bodySpecificDetails(newTableData));
  };

  return (
    <>
      <CTable hover>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            <CTableHeaderCell scope="col">
              {" "}
              <CFormSwitch
                label="select all"
                onClick={toggleAllActive}
                checked={toggleAll}
                onChange={() => {}}
              />
            </CTableHeaderCell>
            <CTableHeaderCell scope="col">Bodytype</CTableHeaderCell>
            <CTableHeaderCell scope="col">Price ($)</CTableHeaderCell>
            <CTableHeaderCell scope="col">Sale Price ($)</CTableHeaderCell>
            <CTableHeaderCell scope="col">Duration (Mins)</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        {status === "success" && (
          <CTableBody>
            {tableData.map(
              (
                { bodytypeName, _id, isActive, price, salePrice, duration },
                i
              ) => {
                return (
                  <CTableRow key={i} color={isActive ? "" : "danger"}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>
                      <CFormSwitch
                        onChange={() => {
                          toggleActive(_id, isActive);
                        }}
                        checked={isActive}
                      />
                    </CTableDataCell>
                    <CTableDataCell>{bodytypeName}</CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === `price`
                          ) {
                            input.focus();
                          }
                        }}
                        type="text"
                        name={`price`}
                        placeholder={`Enter price for ${bodytypeName}`}
                        required
                        disabled={!isActive}
                        value={price}
                        onChange={(e) => handleChange(e, _id)}
                      />
                    </CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        type="number"
                        name="salePrice"
                        placeholder={`Enter sale price for ${bodytypeName}`}
                        required
                        disabled={!isActive}
                        value={salePrice}
                        onChange={(e) => handleChange(e, _id)}
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === `salePrice`
                          ) {
                            input.focus();
                          }
                        }}
                      />
                    </CTableDataCell>
                    <CTableDataCell>
                      <CFormInput
                        ref={(input) => {
                          if (
                            input &&
                            focused._id === _id &&
                            focused.name === `duration`
                          ) {
                            input.focus();
                          }
                        }}
                        type="number"
                        name="duration"
                        placeholder={`Enter duration for ${bodytypeName}`}
                        required
                        disabled={!isActive}
                        value={duration}
                        onChange={(e) => handleChange(e, _id)}
                      />
                    </CTableDataCell>
                  </CTableRow>
                );
              }
            )}
          </CTableBody>
        )}
        {status === "loading" && (
          <CTableBody>
            <CSpinner color="primary" />
            Fetching BodyTypes...
          </CTableBody>
        )}
      </CTable>
    </>
  );
}

export default BodySpecificPriceAndDuration;
