import React, { useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import ListBodytypeTable from "../../../components/admin/bodtypes/ListBodytypeTable/ListBodytypeTable";
import EditBodytype from "./EditBodytype";
// import EditBodytypeForm from "../../../components/admin/bodtypes/EditBodytypeForm/EditBodytypeForm";

function ListBodytype() {
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState(false);

  const editBodytype = (data) => {
    setEditData(data);
    setVisible(true);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emphasis">Bodytype Lists</div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ListBodytypeTable editBodytype={editBodytype} />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <EditBodytype visible={visible} setVisible={setVisible} data={editData} />
    </>
  );
}

export default ListBodytype;
