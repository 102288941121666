import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import AddressDetailsComp from "../../../components/admin/Bookings/AddressDetails";

function AddressDetails({ visible, setVisible, data }) {

     

     return (
          <CModal
               size="xl"
               visible={visible}
               onClose={() => setVisible(preProps => { return { ...preProps, address: false } })}
               backdrop="static"
          >
               <CModalHeader>
                    <CModalTitle>Orderd Address</CModalTitle>
               </CModalHeader>
               <CModalBody>
                    <AddressDetailsComp toggleModal={setVisible} data={data} />
               </CModalBody>
          </CModal>
     );
}

export default AddressDetails;