import React, { useState } from "react";
import { CForm, CFormLabel, CButton, CSpinner } from "@coreui/react";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import "../CreateTimeslot/createTimeslotForm.css";

function EditTimeslotForm({ data, refetch, toggleModal, timezone }) {
  const [validated, setValidated] = useState(false);
  const [startTime, setStartTime] = useState(moment(data.startTime));
  const [endTime, setEndTime] = useState(moment(data.startTime));
  const [loading, setLoading] = useState(false);

  const submitForm = async (event) => {
    console.log(endTime);
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) return event.stopPropagation();

    if (typeof startTime === "number" || typeof endTime === "number") return;
    if (endTime === data.endTime && startTime === data.startTime) return;
    if (!startTime) return toast.warning("Start Time is required");
    if (!endTime) return toast.warning("End Time is required");

    setLoading(true);

    const toastFy = (message, isSuccess) => {
      const tos = isSuccess ? toast.success : toast.error;
      tos(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    try {
      const instance = await axiosInstance();
      const { data: resultData } = await instance.post("/timeslot/edit", {
        timeslotId: data.timeslotId,
        startTime,
        isAvailable: true,
        endTime,
      });
      refetch();
      toggleModal(false);
      resultData.status && toastFy("Time Slot Edited successfully", true);
    } catch (err) {
      !err.response.data.status &&
        toastFy(err.response.data.message || "something went wrong", false);
    } finally {
      setLoading(false);
    }
    setValidated(true);
  };



  const format = "h:mm a";

  return (
    <div>
      <CForm
        className="row g-3 needs-validation mx-auto"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">Start Time</CFormLabel>
          <TimePicker
            showSecond={false}
            defaultValue={moment.tz(data.startTime, timezone)}
            className="xxx"
            onChange={(value) => {
              console.log(value && "startTime", value.format(format));
              setStartTime(
                value.toLocaleString()
              );
            }}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">End Time</CFormLabel>
          <TimePicker
            showSecond={false}
            defaultValue={moment.tz(data.endTime, timezone)}
            className="xxx"
            onChange={(value) => {
              console.log(value && "endTime", value.format(format));
              setEndTime(value.toLocaleString());
            }}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>

        <CButton color="primary" type="submit" disabled={loading}>
          {loading ? (
            <>
              <CSpinner color="light" /> processing...
            </>
          ) : (
            `Edit Timeslot`
          )}
        </CButton>
      </CForm>
    </div>
  );
}

export default EditTimeslotForm;
