import React, { useState } from "react";
import { CFormInput, CForm, CButton, CSpinner } from "@coreui/react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/axiosInstance";
import EditAddressForm from "./EditAddressForm";

function UserProfile({ data, setData, setVisible }) {
  const INITIAL_STATE = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
  };

  const [user, setUser] = useState(INITIAL_STATE);
  const [isActiveAddress, setIsActiveAddress] = useState(data?.address[0]?._id);
  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState({ ...data.address[0] });
  const [isNewAddress, setIsNewAddress] = useState(false);

  console.log(data);
  const submitForm = async (e) => {
    e.preventDefault();
    if (!user.email || !user.lastName || !user.firstName)
      return toast.warning("Please check all the fields");

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))
      // eslint-disable-line
      return toast.warning("please enter a valid email");
    if (
      data.firstName === user.firstName &&
      data.lastName === user.lastName &&
      user.email === data.email
    )
      return;

    setLoading(true);

    try {
      const instance = await axiosInstance();
      const { data: newData } = await instance.post("/profile/edit", {
        uid: data.uid,
        ...user,
      });
      setData((preState) =>
        preState.map((user) => (user.uid === data.uid ? newData.data : user))
      );
      toast.success("User Profile updated");
      setLoading(false);
      setVisible(false);
    } catch (err) {
      toast.warning(err.response.data.message);
      setLoading(false);
      setVisible(false);
    }
  };

  const addNewAddress = () => {
    setAddressData({
      nickname: "",
      streetAddress: "",
      apartment: "",
      city: "",
      province: "",
      country: "",
      postalcode: "",
      cordinates: ["", ""],
    });
    setIsActiveAddress("");
    setIsNewAddress(true);
  };

  return (
    <>
      <div className="profileHead">
        <div className="left">
          <img
            src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/screen-shot-2021-06-16-at-11-21-56-am-1623857560.jpg?resize=480:*"
            alt=""
          />
        </div>
        <div className="right">
          <CForm
            className="row g-3 needs-validation mx-auto"
            noValidate
            // validated={validated}
            onSubmit={submitForm}
          >
            <div className="dflex">
              <div className="inputRow">
                <CFormInput
                  type="text"
                  onChange={(e) =>
                    setUser((preState) => {
                      return { ...preState, firstName: e.target.value };
                    })
                  }
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  value={user.firstName}
                  placeholder="First Name"
                />
              </div>

              <div className="inputRow">
                <CFormInput
                  type="text"
                  onChange={(e) =>
                    setUser((preState) => {
                      return { ...preState, lastName: e.target.value };
                    })
                  }
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  value={user.lastName}
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="inputRow">
              <CFormInput
                type="email"
                onChange={(e) =>
                  setUser((preState) => {
                    return { ...preState, email: e.target.value };
                  })
                }
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                value={user.email}
                placeholder="Email"
              />
            </div>

            <div className="inputRow">
              <CFormInput
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Phone Number"
                disabled={true}
                value={data.phoneNumber}
              />
            </div>

            <CButton color="primary" type="submit" disabled={loading}>
              {loading ? <CSpinner color="light" /> : `Save Changes`}
            </CButton>
          </CForm>
        </div>
      </div>

      <div className="down dflex justify-content-space-evently">
        <div className="addressPre">
          <h5>Edit address</h5>
          <div className="addressContainer">
            {data.address.map((address) => (
              <div
                key={address._id}
                onClick={() => {
                  setIsNewAddress(false);
                  setAddressData(address);
                  setIsActiveAddress(address._id);
                }}
                className={
                  isActiveAddress === address._id
                    ? "selectedAddress"
                    : "addressDetails"
                }
              >
                {" "}
                <span className="indicate">Name</span> : {address.nickname}{" "}
                <span className="indicate">Street Address</span> :{" "}
                {address.streetAddress} <span className="indicate">City</span> :{" "}
                {address.city} ...
              </div>
            ))}
          </div>
          <CButton
            onClick={addNewAddress}
            className="submitButton"
            color="primary"
            type="button"
            disabled={loading}
          >
            Add New Address
          </CButton>
        </div>

        {/* address form  */}

        <div className="addressEditForm">
          <EditAddressForm
            isNewAddress={isNewAddress}
            data={data}
            setData={setData}
            address={addressData}
            setUserAddress={setAddressData}
            setVisible={setVisible}
          />
        </div>
      </div>
    </>
  );
}

export default UserProfile;
