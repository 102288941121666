import React from "react";
import {CModal, CModalHeader, CModalTitle, CModalBody} from "@coreui/react"
import EditServiceForm from "../../../components/admin/services/EditServiceForm/EditServiceForm";

function EditService({visible, setVisible, data}) {
  return (
    <CModal size="xl" visible={visible} onClose={() => setVisible(false)} backdrop="static" >
      <CModalHeader>
        <CModalTitle>Edit {data.title} Service</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <EditServiceForm toggleModal={setVisible}  data={data}/>
      </CModalBody>
      
    </CModal>
  );
}

export default EditService;
