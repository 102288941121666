import React, { useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import ListTimeslotsTable from "../../../components/admin/timeslots/ListTimeslotsTable";
import DetailOfTimeSlot from "./DetailOfTimeSlot";
import { getAllTimeslots } from "../../../utils/query/timeslotQuery";
import { useQuery } from "react-query";
import EditTimeslot from "./EditTimeslot";

function ListTimeslots() {
  const [detailVisible, setDetailVisible] = useState(false);
  const [timeSlotMetaData, setTimeSlotMetaData] = useState([]);
  const [editTimeslot, setEditTimeslot] = useState({});
  const [visible, setVisible] = useState(false);

  const { data, refetch } = useQuery("getAllTimeslots", getAllTimeslots, {});

  const handleEditButtonClick = (data) => {
    setEditTimeslot(data);
    setVisible(true);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emhpasis">Timeslots Lists</div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ListTimeslotsTable
                editTimeslot={editTimeslot}
                setTimeSlot={setTimeSlotMetaData}
                setVisible={setDetailVisible}
                timeSlotDate={timeSlotMetaData}
                data={data}
                refetch={refetch}
                handleEditButtonClick={handleEditButtonClick}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <EditTimeslot
        refetch={refetch}
        visible={visible}
        setVisible={setVisible}
        data={editTimeslot}
      />
      <DetailOfTimeSlot
        timeSlotData={timeSlotMetaData}
        setVisible={setDetailVisible}
        setTimeSlotData={setTimeSlotMetaData}
        visible={detailVisible}
        refetch={refetch}
      />
    </>
  );
}

export default ListTimeslots;
