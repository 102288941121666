import { createSlice } from "@reduxjs/toolkit";

export const editServiceSlice = createSlice({
  name: "editService",
  initialState: {
    serviceId:"",
    serviceName:"",
    description:"",
    type:"interior",
    bodySpecificDetails:[]
  },
  reducers: {
    set_default:(state, action)=>{
      const {title, type, description,_id} = action.payload
      state.serviceName = title;
      state.type = type;
      state.description = description;
      state.serviceId=_id
    },
    set_serviceName: (state, action) => {
      state.serviceName = action.payload;
    },
    set_description: (state, action) => {
      state.description = action.payload;
    },
    set_type: (state, action) => {
      state.type = action.payload;
    },
    set_bodySpecificDetails: (state, action) => {
      state.bodySpecificDetails = action.payload;
    },
    reset_service:(state)=>{
      state.serviceName =""
      state.description =""
      state.bodySpecificDetails =[]
    }
  },
});

export const {set_default, set_serviceName, set_description,set_type, set_bodySpecificDetails,reset_service } = editServiceSlice.actions;

export default editServiceSlice.reducer;
