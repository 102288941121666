import { CButton } from "@coreui/react";
import { toast } from "react-toastify";

const CopyAddress = ({ data }) => {
  const copyToClipboard = () => {
    const detailsText = Object.keys(data)
      .map((key) => `${key}: ${data[key]}`)
      .join("\n");

    const lat = data.cordinates ? data.cordinates[0] : null;
    const lng = data.cordinates ? data.cordinates[1] : null;

    let fullText = detailsText;

    if (lat && lng) {
      const mapLink = `https://www.google.com/maps/place/${lat},${lng}`;
      fullText += `\nLink: ${mapLink}`;
    }

    navigator.clipboard
      .writeText(fullText)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        toast.error("Could not copy text");
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <>
      <CButton color="primary" className="mt-2 w-50" onClick={copyToClipboard}>
        Share Address
      </CButton>
    </>
  );
};

export default CopyAddress;
