import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { getAllTimeslots } from "../../../utils/query/timeslotQuery";
import { useQuery } from "react-query";
import Dayz from 'dayz';
import "dayz/dist/dayz.css";
import moment from 'moment';
import "../../../scss/calender.scss"

function TimeslotCalenderView() {
 

  const { data } = useQuery("getAllTimeslots", getAllTimeslots, {});
  const [calenderEvents, setCalenderEvents]= useState(new Dayz.EventsCollection([]))
  const [currentDate, setCurrentDate]= useState(moment())

  useEffect(()=>{
    if(data && data.data && data.data.data.length){
        const timeSlots = data.data.data
        const events = timeSlots.map((t)=>{
            return {
                content: `${moment(t.startTime).format("HH:mm A")} - ${moment(t.endTime).format("HH:mm A")}`,
                range: moment.range(moment(t.startTime), moment(t.endTime)),
            }
        })
        setCalenderEvents(new Dayz.EventsCollection(events))
    }
  },[data])
  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emhpasis">Timeslots Calender View</div>
            </CCol>
          </CRow>
          <CRow>
            
          </CRow>
          <CRow>
            <CCol>
            <div className="calender-wrapper">
                <Dayz   
                        events={calenderEvents}
                        highlightDays={[currentDate]}
                    >
                </Dayz>
            </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    
    </>
  );
}

export default TimeslotCalenderView;
