import { useState, useEffect } from "react";
import CopyAddress from "./CopyAddress";

function AddressDetailsComp({ toggleModal, data }) {
  const [embedLink, setEmbedLink] = useState("");

  const makeDetails = () =>
    Object.keys(data).map((key, index) => {
      return (
        <p key={index}>
          <span className="title">{`${key} `}</span> : {`${data[key]}`}
        </p>
      );
    });

  const createEmbedLink = () => {
    const baseEmbedUrl = "https://www.google.com/maps/embed?pb=";
    const lat = data.cordinates ? data.cordinates[0] : null;
    const lon = data.cordinates ? data.cordinates[1] : null;

    if (lat && lon) {
      const embedLink = `${baseEmbedUrl}!1m18!1m12!1m3!1d3153.8354345093724!2d${lon}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085818c98e848df%3A0x4a0ed1a6b58d2c71!2sSome+Place!5e0!3m2!1sen!2sus!4v1618301584565!5m2!1sen!2sus`;
      return embedLink;
    }
    return "";
  };

  useEffect(() => {
    const transformedLink = createEmbedLink();
    setEmbedLink(transformedLink);
  }, [data]);

  return (
    <div className="vechile">
      <div className="vechileImg">
        <iframe
          src={
            embedLink
              ? embedLink
              : "https://cdn.dribbble.com/users/1037219/screenshots/15697560/media/de66d1b968747205e88a1620fc36beb7.jpg?compress=1&resize=400x300&vertical=top"
          }
          width={400}
          height={300}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      <div className="details ml-4">
        {makeDetails()} <CopyAddress data={data} />
      </div>
    </div>
  );
}

export default AddressDetailsComp;
