import { CButton, CFormSelect } from "@coreui/react";
import React, { useState } from "react";
import moment from "moment";
import "./ViewBookingContent.css";
import { useQuery } from "react-query";
import { axiosInstance } from "../../../utils/axiosInstance";
import { getAllVans } from "../../../utils/query/vanInstance";
import swal from "sweetalert2";
import { useSelector } from "react-redux";
import CopyAddress from "./CopyAddress";

function ViewBookingContent({ data }) {
  const vanData = useQuery("getAllVans", getAllVans);
  const [assignedVan, setAssignedVan] = useState("");
  const timezone = useSelector((state) => state.timezone);
  const assignVan = async (value) => {
    const bookingId = data?.bookingId;
    const vanId = value;
    const instance = await axiosInstance();
    instance
      .post("/booking/assign-van", { vanId, bookingId })
      .then((res) => {
        if (res.data.status) {
          swal.fire("Success", "Van Assigned Successfully", "success");
          setAssignedVan(res.data.data.van);
        }
      })
      .catch((err) => {
        console.log(err);
        swal.fire(
          "Something went wrong!",
          err.response.data.message,
          "warning"
        );
      });
  };

  return (
    <div className="cotainer p-5 ">
      <div className="row shadow p-3 mb-5 bg-body rounded">
        <div className="col-6">
          <p>
            Booking Id: <b>{data?.bookingId}</b>
          </p>
          <p>Status: {orderStatusButton(data.status)}</p>
          <p>
            CreatedAt:{" "}
            <b>{moment.tz(data?.createdAt, timezone).format("d-mm-yy H:MM")}</b>
          </p>
          <p>
            updatedAt:{" "}
            <b>{moment.tz(data?.updatedAt, timezone).format("d-mm-yy H:MM")}</b>
          </p>
        </div>
        <div className="col-6 ">
          <p>
            Customer Name:{" "}
            <b>
              {data?.customer?.firstName} {data?.customer?.lastName}
            </b>
          </p>
          <p>
            Email: <b>{data?.customer?.email}</b>
          </p>
          <p>
            Phone: <b>{data?.customer?.phoneNumber}</b>
          </p>
          <p>
            Stripe CusId: <b>{data?.customer?.stripeCustomerId}</b>
          </p>
          <p>
            UID: <b>{data?.customer?.uid}</b>
          </p>
        </div>
      </div>
      <div className="row shadow p-3 mb-5 bg-body rounded">
        <div className="col-6">
          <h4>Pricing Details</h4>
          <p>
            Stripe payment ID:{" "}
            <span style={{ color: "#132c80" }}>{data?.stripePaymentId}</span>
          </p>
          <p>
            Package Price: {data?.packageDetails?.packagePriceInCents / 100}
          </p>
          <p>Tax amount: {data?.taxAmountInCents / 100}</p>
          <p>Booking amount: {data?.totalBookingAmountInCents / 100}</p>
        </div>
        <div className="col-6">
          <h4>Status History</h4>
          {data?.statusHistory?.reverse()?.map((item, index) => {
            return (
              <div
                className="row shadow pt-2 pl-2 mb-1 bg-light rounded"
                key={index}
              >
                <p className="col-6">
                  Status: {orderStatusButton(item?.status)}
                </p>
                <p className="col-6">
                  Time:{" "}
                  {moment.tz(item.changedAt, timezone).format("d-mm-yy H:MM")}
                </p>
                <p>Changed By: {item?.changedBy}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row shadow p-3 mb-5 bg-body rounded">
        <div className="col-6">
          <div className="rounded shadow p-2">
            <h4>Address</h4>
            <p>Nickname: {data?.address?.nickname}</p>
            <p>Street Address: {data?.address?.streetAddress}</p>
            <p>Apartment: {data?.address?.apartment}</p>
            <p>City: {data?.address?.city}</p>
            <p>Province: {data?.address?.province}</p>
            <p>Country: {data?.address?.country}</p>
            <p>Postal Code: {data?.address?.postalcode}</p>
            <p>
              Coordinates: {data?.address?.cordinates[0]},{" "}
              {data?.address?.cordinates[1]}
            </p>
            <CopyAddress data={data?.address} />
          </div>
        </div>
        <div className="col-6">
          <h4>Vehicle to clean</h4>
          <p>
            Bodytype:{" "}
            <CButton color="info" size="sm">
              {data?.vehicle?.bodytypeName}
            </CButton>
          </p>
          <p>
            Number Plate:{" "}
            <CButton color="success" size="sm">
              {data?.vehicle?.numberPlate}
            </CButton>
          </p>
          <p>
            Make and Model:{" "}
            <b>
              {data?.vehicle?.make} {data?.vehicle?.model}
            </b>
          </p>
          <p>
            Nickname: <b>{data?.vehicle?.nickname}</b>
          </p>
          <div className="rounded shadow p-2 mt-3">
            {data?.van ? (
              <>
                <h4>Van</h4>
                <p>Van Id: {data?.van?.kNumber}</p>
                <p>Number Plate: {data?.van?.numberPlate}</p>
              </>
            ) : (
              <>
                <h4>Assign Van</h4>
                <CFormSelect
                  onChange={(e) => {
                    assignVan(e.target.value);
                  }}
                  value={assignedVan}
                  style={{ marginTop: "1rem" }}
                  aria-label="Default select example"
                >
                  <option>Select Van</option>
                  {vanData.isFetched &&
                    vanData?.data?.data.data.map((van, i) => {
                      return (
                        <option key={i} value={van._id}>
                          {van.kNumber} - {van.numberPlate}
                        </option>
                      );
                    })}
                </CFormSelect>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row shadow p-3 mb-5 bg-body rounded">
        <div className="col-6">
          <h4>Package Details</h4>
          <p>
            Package Name: <b>{data?.packageDetails?.packageName}</b>
          </p>
          <p>
            Package Price:{" "}
            <b>{data?.packageDetails?.packagePriceInCents / 100}</b>
          </p>
          <p>
            Package Duration:{" "}
            <b>{data?.packageDetails?.packageDurationInMins} minutes</b>
          </p>
        </div>
        <div className="col-6 rounded shadow p-2">
          <h4>Timeslots</h4>
          <p>
            Start time:{" "}
            {moment
              .tz(data?.timeslot?.startTime, timezone)
              .format("d-mm-yy H:MM")}
          </p>
          <p>
            End time:{" "}
            {moment
              .tz(data?.timeslot?.endTime, timezone)
              .format("d-mm-yy H:MM")}
          </p>
        </div>
      </div>
      <div className="row shadow p-3 mb-5 bg-body rounded">
        <div className="col-6">
          <h5>Included Services</h5>
          <div
            style={{
              height: "370px",
              overflowY: "auto",
            }}
          >
            {data?.packageDetails?.includedServices?.map(
              ({ serviceName, type, _id }) => {
                return (
                  <div className="rounded shadow p-2" key={_id}>
                    <p>{serviceName}</p>
                    <CButton color="warning" size="sm">
                      {type}
                    </CButton>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="col-6">
          <h5>Additional Services</h5>
          <div>
            {data?.packageDetails?.additionalServices?.map(
              ({ serviceName, type, _id }) => {
                return (
                  <div className="rounded shadow p-2" key={_id}>
                    <p>{serviceName}</p>
                    <CButton color="warning" size="sm">
                      {type}
                    </CButton>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const orderStatusButton = (status) => {
  switch (status) {
    case "initiated":
      return (
        <CButton color="secondary" size="sm">
          Initiated
        </CButton>
      );
    case "payment-failed":
      return (
        <CButton color="danger" size="sm" className="ml-1">
          Payment Failed
        </CButton>
      );
    case "payment-success":
      return (
        <CButton color="success" size="sm" className="ml-1">
          Payment Success
        </CButton>
      );
    case "payment-processing":
      return (
        <CButton color="warning" size="sm" className="ml-1">
          Payment Processing
        </CButton>
      );
    case "on-way":
      return (
        <CButton color="info" size="sm" className="ml-1">
          On Way
        </CButton>
      );
    case "arrived":
      return (
        <CButton color="info" size="sm" className="ml-1">
          Arrived
        </CButton>
      );
    case "wash-in-progress":
      return (
        <CButton color="info" size="sm" className="ml-1">
          Wash In Progress
        </CButton>
      );
    case "dispute":
      return (
        <CButton color="danger" size="sm" className="ml-1">
          Dispute
        </CButton>
      );
    case "completed":
      return (
        <CButton color="success" size="sm" className="ml-1">
          Completed
        </CButton>
      );
    case "cancelled":
      return (
        <CButton color="danger" size="sm" className="ml-1">
          Cancelled
        </CButton>
      );
    case "refund":
      return (
        <CButton color="danger" size="sm" className="ml-1">
          Refund
        </CButton>
      );
    default:
      return (
        <CButton color="gray" size="sm" className="ml-1">
          Pendings
        </CButton>
      );
  }
};

export default ViewBookingContent;
