import React from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
} from "@coreui/react";
import CreateUserForm from "../../../components/admin/Users/CreateUserForm";



function CreateUser() {
 

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={10}>
              <h4 className="card-title mb-0">KIVLIK</h4>
              <div className="small text-medium-emphasis mb-2">Create User</div>
            </CCol>
          </CRow>
          <CRow className="mt-4">
                <CCol>
            <CreateUserForm />
            </CCol>
            </CRow>
        </CCardBody>
        
      </CCard>
    </>
  );
}

export default CreateUser;
