import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilShortText,
  cilSpeedometer,
  cilPlus,
  cilLibrary,
  cilLibraryAdd,
  cilList,
  cilUser,
  cilCalendar
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _adminNav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/admin",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavTitle,
    name: "Package",
  },
  {
    component: CNavGroup,
    name: "Services",
    to: "/admin/services",
    icon: <CIcon icon={cilShortText} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Create Service",
        icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
        to: "/admin/services/create",
      },
      {
        component: CNavItem,
        name: "List Services",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/services/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Packages",
    to: "/admin/package",
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Create Package",
        icon: (
          <CIcon
            icon={cilLibraryAdd}
            customClassName="nav-icon inner_navitem"
          />
        ),
        to: "/admin/packages/create",
      },
      {
        component: CNavItem,
        name: "List Packages",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/packages/list",
      },
    ],
  },
  // to booking
  {
    component: CNavTitle,
    name: "Bookings",
  },

  {
    component: CNavGroup,
    name: "Bookings",
    to: "/admin/user", // to the path of the vans
    icon: <CIcon icon={cilShortText} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "List Bookings",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/bookings/list",
      },
      // {
      //   component: CNavItem,
      //   icon: <CIcon icon={cilUser} customClassName="nav-icon inner_navitem" />,
      //   to: "/admin/vans/create",
      // },
    ],
  },

  //to subscription
  // {
  //   component: CNavTitle,
  //   name: "Subscription",
  // },

  // {
  //   component: CNavGroup,
  //   name: "Subscription",
  //   to: "/admin/subscription", // to the path of the subscription
  //   icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Calender",
  //       icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
  //       to: "/admin/subscription/calender",
  //     },
  //     {
  //       component: CNavItem,
  //       name: "Configure Timeslots",
  //       icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
  //       to: "/admin/subscription/timeslots",
  //     },
  //   ],
  // },

  // to users
  {
    component: CNavTitle,
    name: "Users",
  },

  {
    component: CNavGroup,
    name: "User",
    to: "/admin/user", // to the path of the vans
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "List User",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/user/list",
      },
      {
        component: CNavItem,
        name: "Executives",
        icon: <CIcon icon={cilUser} customClassName="nav-icon inner_navitem" />,
        to: "/admin/user/executive",
      },
      {
        component: CNavItem,
        name: "Create User",
        icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
        to: "/admin/user/create",
      },
    ],
  },

  // configurations
  {
    component: CNavTitle,
    name: "Configurations",
  },
  {
    component: CNavGroup,
    name: "Bodytype",
    to: "/admin/bodytypes",
    icon: <CIcon icon={cilShortText} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Create Bodytype",
        icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
        to: "/admin/bodytypes/create",
      },
      {
        component: CNavItem,
        name: "List Bodytypes",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/bodytypes/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Timeslot",
    to: "/admin/time slots",
    icon: <CIcon icon={cilShortText} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Configure Time slots",
        icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
        to: "/admin/timeslots/create",
      },
      {
        component: CNavItem,
        name: "List Time slots",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/timeslots/list",
      },
      {
        component: CNavItem,
        name: "Calender View",
        icon: <CIcon icon={cilCalendar} customClassName="nav-icon inner_navitem" />,
        to: "/admin/timeslots/calender",
      },
    ],
  },
  //  to the vans
  {
    component: CNavGroup,
    name: "Vans",
    to: "/admin/vans", // to the path of the vans
    icon: <CIcon icon={cilShortText} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Create Van",
        icon: <CIcon icon={cilPlus} customClassName="nav-icon inner_navitem" />,
        to: "/admin/vans/create",
      },
      {
        component: CNavItem,
        name: "List Van",
        icon: <CIcon icon={cilList} customClassName="nav-icon inner_navitem" />,
        to: "/admin/vans/list",
      },
    ],
  },
];

export default _adminNav;
