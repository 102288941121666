import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axiosInstance";

// console.log(firebase.auth());
const SignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSented, setOtpSented] = useState(false);
  const [otp, setOtp] = useState("");
  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
          console.log("recaptcha verified");
        },
        defaultCountry: "CA",
      }
    );
  };

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    configureCaptcha();
    console.log(phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    const sendOtp = () => {
      return firebase
        .auth()
        .signInWithPhoneNumber(`+1${phoneNumber}`, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log("otp send");
          setOtpSented(true);
        })
        .catch((error) => {
          toast.error("SMS not sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log("SMS not sent");
        });
    };
    let instance = await axiosInstance();
    instance
      .post("/profile/checkUser", {
        phoneNumber: `+1${phoneNumber}`,
        role: "admin",
      })
      .then((res) => {
        if (res.data.status) {
          toast.promise(sendOtp, {
            pending: "OTP is Sending...",
            success: "OTP Sended SuccessFully 👌",
            error: "OTP Sending failed 🤯",
          });
        } else {
          toast.warning("You cant login");
        }
      });
  };

  const onSubmitOtp = (e) => {
    const code = otp;
    if (code.length !== 6) {
      return toast.warn("Enter 6 digit OTP");
    }
    const id = toast.loading("Verifying OTP...");
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log("User signed in successfully.");
        console.log({ result });
        const user = result.user;
        console.log(JSON.stringify(user));
        // toast.success("OTP is verified", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        toast.update(id, {
          render: "OTP is verified",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // toast.error("OTP is not correct", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        toast.update(id, {
          render: "OTP is invalid",
          type: "error",
          isLoading: false,
          autoClose: 3500,
        });
        console.log("User couldn't sign in (bad verification code?)");
      });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    className="row g-3 needs-validation"
                    noValidate
                    onSubmit={onSignInSubmit}
                  >
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        {/* <CIcon icon={cilUser} />  */}+1
                      </CInputGroupText>
                      <CFormInput
                        type="number"
                        disabled={otpSented}
                        value={phoneNumber}
                        onChange={(e) => {
                          if (
                            e.target.value.length <= 10 &&
                            e.target.value.length >= 0
                          ) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                        placeholder="Enter Phone Number"
                        autoComplete="phonenumber"
                      />

                      <div id="sign-in-button" />

                      {otpSented && (
                        <div className="col-12 mt-3">
                          <CFormInput
                            type="number"
                            value={otp}
                            onChange={(e) => {
                              if (
                                e.target.value.length <= 6 &&
                                e.target.value.length >= 0
                              ) {
                                setOtp(e.target.value);
                              }
                            }}
                            placeholder="Enter Otp here..."
                          />
                        </div>
                      )}
                    </CInputGroup>

                    <CRow>
                      <CCol xs={6}>
                        {!otpSented ? (
                          <CButton
                            color="primary"
                            type="submit"
                            className="px-4"
                          >
                            Login
                          </CButton>
                        ) : (
                          <CButton
                            color="primary"
                            className="px-4"
                            onClick={onSubmitOtp}
                          >
                            Verify OTP
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default SignIn;
