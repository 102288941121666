import React, { useEffect, useState } from "react";
import { CForm, CFormLabel, CButton, CSpinner } from "@coreui/react";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import TimePicker from "rc-time-picker";
import TimezoneSelect from "react-timezone-select";
import "rc-time-picker/assets/index.css";
import "./createTimeslotForm.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTimezone } from "../../../../Redux/timezone/timezoneSlice";

function CreateTimeslotForm({ setTimeSloteData }) {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const timezone = useSelector((state) => state.timezone);
  const [timezoneData, setTimezoneData] = useState(timezone);
  const dispatch = useDispatch();

  useEffect(() => {
    setStartTime(moment().tz(timezone));
    setEndTime(moment().tz(timezone));
  }, [timezone]);

  const onTimezoneChange = async (e) => {
    e.preventDefault();
    dispatch(setTimezone(timezoneData.value));
    // setTimezone(timezoneData.value);
    console.log(timezone);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    console.log(startTime, endTime);
    console.log(time);
    const form = event.currentTarget;
    if (!form.checkValidity()) return event.stopPropagation();

    if (!startTime) return toast.warning("Start Time is required");
    if (!endTime) return toast.warning("End Time is required");
    if (Date.parse(startTime) >= Date.parse(endTime))
      return toast.warning("Please enter a valid timeline");

    setLoading(true);
    //TODO: post below data to server
    console.log({ startTime, endTime });

    const instance = await axiosInstance();
    try {
      const { data } = await instance.post("/timeslot/add", {
        startTime,
        endTime,
      });
      if (data.status) {
        setTimeSloteData(data.data);
        console.log(data.data);
        toast.success("Timeslot is Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.log(err.response.data);
      if (!err.response.data.status) {
        toast.error(err.response.data.message || "something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoading(false);
      // setStartTime("");
      // setEndTime("");
    }
    setValidated(true);
  };

  const format = "h:mm a";

  // const now = moment().hour(0).minute(0);
  const time = moment().tz(timezone);

  return (
    <div className="dflex">
      <CForm
        className="row g-3 needs-validation mx-auto"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="dflex">
          <div className="mb-3 row col-md-4">
            <CFormLabel htmlFor="serviceName">Start Time</CFormLabel>
            <TimePicker
              showSecond={false}
              defaultValue=""
              value={startTime}
              className="xxx"
              onChange={(value) => {
                console.log(value && "startTime", value.format(format));
                setStartTime(value);
              }}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>
          <div className="mb-3 row col-md-4">
            <CFormLabel htmlFor="serviceName">End Time</CFormLabel>
            <TimePicker
              showSecond={false}
              defaultValue=""
              value={endTime}
              className="xxx"
              onChange={(value) => {
                console.log(value && "endTime", value.format(format));
                setEndTime(value);
              }}
              format={format}
              use12Hours
              inputReadOnly
            />
          </div>

          <div className="mb-3 row col-md-4 ms-3" style={{ width: "290px" }}>
            <CFormLabel htmlFor="serviceName">Set Timezone</CFormLabel>
            <TimezoneSelect
              value={timezoneData}
              onChange={(value) => {
                console.log(value.value);
                setTimezoneData(value);
              }}
            />
          </div>
        </div>

        <div className="dflex justify-content-evenly">
          <CButton
            disabled={loading}
            className="col-md-4"
            color="primary"
            type="submit"
          >
            {loading ? (
              <>
                <CSpinner color="primary" /> processing...
              </>
            ) : (
              ` Create Timeslot`
            )}
          </CButton>

          <CButton
            type="button"
            className="col-md-2"
            color="primary"
            onClick={onTimezoneChange}
          >
            Change Timezone
          </CButton>
        </div>
      </CForm>
    </div>
  );
}

export default CreateTimeslotForm;
