import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import EditTimeslotForm from "../../../components/admin/timeslots/EditTimeslotForm/EditTimeslotForm";
import { useSelector } from "react-redux";

function EditTimeslot({ visible, setVisible, refetch, data }) {
  const timezone = useSelector((state) => state.timezone);
  return (
    <CModal
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>Edit Timeslot</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <EditTimeslotForm
          toggleModal={setVisible}
          refetch={refetch}
          data={data}
          timezone={timezone}
        />
      </CModalBody>
    </CModal>
  );
}

export default EditTimeslot;
