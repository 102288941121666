import React, { useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import ListVansTable from "../../../components/admin/Vans/ListVans";
import EditVan from "./EditVan";
import { useQuery } from "react-query";
import { getAllVans } from "../../../utils/query/vanInstance";

function ListVans() {
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({});

  const { data, status, refetch } = useQuery("getAllVans", getAllVans);

  const editVanData = (data) => {
    setEditData(data);
    setVisible(true);
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 className="card-title mb-0">KIVLIK</h4>
              <div className="small text-medium-emphasis mb-5">List Vans</div>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="overFlowX">
              <ListVansTable
                editVanData={editVanData}
                data={data}
                status={status}
                refetch={refetch}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <EditVan
          setVisible={setVisible}
          visible={visible}
          data={editData}
          refetch={refetch}
        />
      </CCard>
    </>
  );
}

export default ListVans;
