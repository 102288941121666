import AdminDashBoardComponent from "../../../components/admin/Dashboard";
import { useQuery } from "react-query";
import { getAllUsers } from "../../../utils/query/UsersQuery";
import { getAllBookings } from "../../../utils/query/bookings";
import { getAllServices } from "../../../utils/query/serviceQuery";
import { getAllTimeslots } from "../../../utils/query/timeslotQuery";
import { getAllBodytype } from "../../../utils/query/bodytypeQuery";
import { getAllPackages } from "../../../utils/query/packageQuery";
import { CSpinner } from "@coreui/react";

const AdminDashboard = () => {
  const userData = useQuery("getAllUsers", getAllUsers, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const bookingsData = useQuery("getAllBookings", getAllBookings, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const serviceData = useQuery("getAllServices", getAllServices, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const timeslotData = useQuery("getAllTimeslots", getAllTimeslots, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const bodytypeData = useQuery("getAllBodytype", getAllBodytype, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const packageData = useQuery("getAllPackages", getAllPackages, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const allQueriesSuccess =
    userData.isSuccess &&
    bookingsData.isSuccess &&
    serviceData.isSuccess &&
    timeslotData.isSuccess &&
    bodytypeData.isSuccess &&
    packageData.isSuccess;

  if (!allQueriesSuccess) {
    return (
      <tbody>
        <CSpinner color="primary" />
        loading....
      </tbody>
    );
  }
  return (
    <AdminDashBoardComponent
      userData={userData?.data?.data?.data}
      bookingsData={bookingsData?.data?.data?.data}
      serviceData={serviceData?.data?.data?.data}
      timeslotData={timeslotData?.data?.data?.data}
      bodytypeData={bodytypeData?.data?.data?.data}
      packageData={packageData?.data?.data?.data}
    />
  );
};

export default AdminDashboard;
