import { createSlice } from "@reduxjs/toolkit";

const timezoneSlice = createSlice({
  name: "timezone",
  initialState: Intl.DateTimeFormat().resolvedOptions().timeZone,
  reducers: {
    setTimezone: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTimezone } = timezoneSlice.actions;

export default timezoneSlice.reducer;
