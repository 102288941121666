import React, { useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import ListServiceTable from "../../../components/admin/services/ListServiceTable/ListServiceTable";
import EditService from "./EditService";
import { axiosInstance } from "../../../utils/axiosInstance";
import { toast } from "react-toastify";

function ListServices() {
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState(false);
  const [sortedServices, setSortedServices] = useState([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const editService = (data) => {
    setEditData(data);
    setVisible(true);
  };

  const changeServiceOrder = async () => {
    const instance = await axiosInstance();
    instance
      .put(`/package/service/edit-sequence`, {
        sequenceService: sortedServices,
      })
      .then((res) => {
        toast.success("Services Rearranged!");
        // console.log(res);
        setShowSaveBtn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow className="d-flex justify-content-between">
            <CCol xs={6}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emphasis">Service Lists</div>
            </CCol>
            <CCol xs={6} className="text-end">
              {showSaveBtn && (
                <button
                  className="btn btn-primary rounded-pill"
                  onClick={changeServiceOrder}
                  type="button"
                >
                  Save
                </button>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ListServiceTable
                setSortedServices={setSortedServices}
                editService={editService}
                setShowSaveBtn={setShowSaveBtn}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <EditService visible={visible} setVisible={setVisible} data={editData} />
    </>
  );
}

export default ListServices;
