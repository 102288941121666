import React from "react";
import { Outlet } from "react-router-dom";
import firebase from "../../config/firebase";
// import { useDispatch } from "react-redux";
// import { set_user } from "../../Redux/profile/profileSlice";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";

function MainLayout() {
  // const dispatch = useDispatch();
  let navigate = useNavigate();
  
  firebase.auth().onAuthStateChanged(async(user) => {
    const instance = await axiosInstance();
    console.log("auth changed");
    console.log(user);
    if (user) {
      // dispatch(set_user(user));
      instance
        .post("/profile/checkUser", {
          phoneNumber: user.phoneNumber,
          role: "admin",
        })
        .then((res) => {
          if (res.data.status) {
            navigate("admin");
          }
        });
    }else{
      // dispatch(set_user({}));
      let unProtectedRoute = ["/", '/signin', "/signup"]
      console.log(window.location.pathname)
      if(!unProtectedRoute.find((a)=>a===window.location.pathname)){

        navigate({pathname:"/signin"})
      }
    }

  });

  return (
    <div>
      {/* <h1>Header</h1> */}
      <Outlet />
      {/* <h1>Footer</h1> */}
    </div>
  );
}

export default MainLayout;
