import { useEffect, useState } from "react";
import { FiUsers, FiClock } from "react-icons/fi";
import { TbBrandBooking } from "react-icons/tb";
import { FaServicestack, FaCar } from "react-icons/fa";
import InformationCards from "./InformationCards";
import Charts from "./Charts";
import DashboardUsers from "./DashboardUsers";
import DashboardBookings from "./DashboardBookings";
import "./Dashboard.css";

const AdminDashBoardComponent = ({
  userData,
  bookingsData,
  serviceData,
  timeslotData,
  bodytypeData,
  packageData,
}) => {
  const [cardData, setCardData] = useState([]);

  const limit = {
    userLimit: userData.length,
    bookingsLimit: bookingsData.length,
    serviceLimit: serviceData.length,
    bodyTypeLimit: bodytypeData.length,
    timeslotLimit: timeslotData.length,
  };

  useEffect(() => {
    const newData = [
      {
        color: "primary",
        textColor: "white",
        data: limit.userLimit,
        header: "Total users",
        icon: <FiUsers />,
      },
      {
        color: "info",
        textColor: "white",
        data: limit.bookingsLimit,
        header: "Current Bookings",
        icon: <TbBrandBooking />,
      },
      {
        color: "warning",
        textColor: "white",
        data: limit.serviceLimit,
        header: "Total Services",
        icon: <FaServicestack />,
      },
      {
        color: "danger",
        textColor: "white",
        data: limit.bodyTypeLimit,
        header: "Body Types",
        icon: <FaCar />,
      },
      {
        color: "success",
        textColor: "white",
        data: limit.timeslotLimit,
        header: "Available Timeslots",
        icon: <FiClock />,
      },
    ];
    setCardData(newData);
  }, [
    limit.userLimit,
    limit.serviceLimit,
    limit.bodyTypeLimit,
    limit.bookingsLimit,
    limit.timeslotLimit,
  ]);

  return (
    <div>
      <span className="informationCard ">
        {cardData.map((data, i) => (
          <InformationCards key={i} cardData={data} />
        ))}
      </span>
      <div>
        <Charts
          userData={userData}
          bookingsData={bookingsData}
          packageData={packageData}
        />
      </div>
      <div className="d-flex flex-column flex-xl-row">
        <DashboardUsers data={userData} />
        <DashboardBookings data={bookingsData} />
      </div>
    </div>
  );
};

export default AdminDashBoardComponent;
