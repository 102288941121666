import React, { useState } from "react";
import { CForm, CFormLabel, CFormInput, CButton } from "@coreui/react";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { validateURL } from "../../../../utils";

function CreateBodytypeForm() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [validated, setValidated] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const isvalidatedUrl = validateURL(imageUrl);
      if (!isvalidatedUrl) {
        return toast.error("Enter an valid image url", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      //TODO: post below data to server
      console.log({
        bodytypeName: name,
        image: imageUrl,
      });

      const instance = await axiosInstance();
      instance
        .post("/bodytype/add", {
          bodytypeName: name,
          image: imageUrl,
        })
        .then((res) => {
          if (res.data.status) {
            toast.success("Bodytype is Created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setName("");
            setImageUrl("");
            navigate({ pathname: "/admin/bodytypes/list" });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          if (!err.response.data.status) {
            toast.error(err.response.data.message || "something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
    setValidated(true);
  };
  return (
    <div>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3">
          <CFormLabel htmlFor="bodytypeName">Bodytype Name</CFormLabel>
          <CFormInput
            type="text"
            id="bodytypeName"
            placeholder="Enter Bodytype Name here"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="imageLink">Image Link</CFormLabel>
          <CFormInput
            type="text"
            id="imageLink"
            placeholder="Paste Image Link here"
            required
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </div>
        {imageUrl && (
          <div className="mb-3">
             {validateURL(imageUrl) ? <img
              src={imageUrl}
              alt="bodytype"
              style={{ width: "25rem" }}
            /> : <p>Link must be proper</p>}
          </div>
        )}

        <CButton color="primary" type="submit">
          Create Bodytype
        </CButton>
      </CForm>
    </div>
  );
}

export default CreateBodytypeForm;
