import React from 'react'

function PackageDetailsComp({ toggleModal, data }) {


     return (



          <div className='vechile bg-image ' >

               <div className=" packgeOuter ">
                    <center> <h2> {data?.packageName} </h2></center>
                    {/* <p> {JSON.stringify(data)} </p> */}

                    <div className="allServices">
                         <div className="services">
                              <center> <h3>Included services</h3>  </center>
                              {
                                   data?.includedServices?.length ? data?.includedServices?.map((service, i) => {
                                        return <>  <span > {i < 9 ? `0${i + 1}` : i + 1} </span>  <p> {service.serviceName} <code style={{ color: "blue", fontWeight: "400" }} > ({service.type})  </code> </p> </>
                                   })
                                        : <h4> NO Included services</h4>
                              }
                         </div>

                         <div className="services">
                              <center> <h3>Additional  services</h3>  </center>

                              {
                                   data?.additionalServices.length ? data?.additionalServices?.map((service, i) => {
                                        return <>   <span > {i < 9 ? `0${i + 1}` : i + 1} </span>  <p> {service.serviceName}   <code style={{ color: "blue", fontWeight: "400" }} > ({service.type})  </code>
                                             <br />
                                             <code className='options '> Duration : {service.durationInMins} Minutes </code>
                                             <code className='options '> Price :  ¢{service.priceInCents} </code>
                                        </p> </>
                                   })
                                        : <h4> NO Additional Services </h4>

                              }
                         </div>
                    </div>

               </div>
          </div>


     )
}

export default PackageDetailsComp