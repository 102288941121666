import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CreateBodytypeForm from "../../../components/admin/bodtypes/CreateBodytypeForm/CreateBodytypeForm";

function CreateBodytype() {
  return (
    <>
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              KIVLIK
            </h4>
            <div className="small text-medium-emphasis">Create Package</div>
          </CCol>
        </CRow>
          <CreateBodytypeForm />
      </CCardBody>
    </CCard>
  </>
  )
}

export default CreateBodytype