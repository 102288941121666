import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CreateVanFrom from "../../../components/admin/Vans/CreateVans/CreateVanFrom";

function CreateVans() {
  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 className="card-title mb-0">KIVLIK</h4>
              <div className="small text-medium-emphasis mb-5">Create Vans</div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CreateVanFrom />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
}

export default CreateVans;
