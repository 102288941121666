import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageName: "",
  description: "",
  serviceList: [],
  includedServices: [],
  additionalServices: [],
  bodySpecificDetails: [],
};

export const createPackageSlice = createSlice({
  name: "createPackage",
  initialState,
  reducers: {
    set_packageName: (state, action) => {
      state.packageName = action.payload.packageName;
    },
    set_description: (state, action) => {
      state.description = action.payload.description;
    },
    set_includedServices: (state, action) => {
      state.includedServices = action.payload.includedServices;
    },
    set_additionalServices: (state, action) => {
      state.additionalServices = action.payload.additionalServices;
    },
    set_additionalAvailableServiceList: (state, action) => {
      state.additionalAvailableServiceList =
        action.payload.additionalAvailableServiceList;
    },
    set_bodySpecificDetails: (state, action) => {
      state.bodySpecificDetails = action.payload;
    },
    set_serviceList: (state, action) => {
      state.serviceList = action.payload;
    },
    reset_package: (state, action) => {
      state.packageName = "";
      state.description = "";
      state.serviceList = [
      ];
      state.includedServices = [];
      state.additionalServices = [];
      state.bodySpecificDetails = [];
    },
  },
});

export const {
  set_serviceList,
  set_packageName,
  set_description,
  set_includedServices,
  set_additionalServices,
  set_additionalAvailableServiceList,
  set_bodySpecificDetails,
  reset_package
} = createPackageSlice.actions;

export default createPackageSlice.reducer;
