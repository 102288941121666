import React from 'react'

function ViewVechile({ data, toggleModal }) {


     const makeDetails = () => Object.keys(data).map((key, index) => {
          return <p> <span className='title' > {`${key} `} </span> : {`${data[key]}`} </p>
     });


     return (
          <div className='vechile' >

               <div className="vechileImg">
                    <img src="https://imgd.aeplcdn.com/600x337/n/cw/ec/41197/hyundai-verna-right-front-three-quarter7.jpeg?q=75" alt="" />
               </div>

               <div className="details">
                    {
                         makeDetails()
                    }
               </div>
          </div>
     )
}

export default ViewVechile