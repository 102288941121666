import React, { useState } from "react";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CInputGroup,
  CDropdownToggle,
  CDropdownItem,
  CDropdown,
  CDropdownMenu,
  CFormSwitch,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  reset_service,
  set_user_field,
} from "../../../../Redux/user/userSlice";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const ROLES = [
  {
    value: "customer",
    label: "Customer",
  },
  {
    value: "executive",
    label: "Executive",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

function CreateUserForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [validated, setValidated] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // validate if any of the switch is checked
      if (user.roles.length === 0) {
        toast.error("Please select at least one role");
      }
      event.stopPropagation();

    } else {
      const { firstName, lastName, roles, email, phoneNumber } = user;

      try {
        const instance = await axiosInstance();
        const { data } = await instance.post("/profile/create-user", {
          firstName,
          lastName,
          roles,
          email,
          phoneNumber:"+91"+phoneNumber,
        });
        console.log(data);
        toast.success("User Created Successfully");
        dispatch(reset_service());
        navigate("/admin/user/list");
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    setValidated(true);
  };

  return (
    <div>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3 row">
          <div className="col">
            <CFormLabel htmlFor="firstName">First Name</CFormLabel>
            <CFormInput
              type="text"
              id="firstName"
              placeholder="Enter First Name here"
              required
              value={user.firstName}
              onChange={(e) =>
                dispatch(
                  set_user_field({ value: e.target.value, field: "firstName" })
                )
              }
            />
          </div>
          <div className="col">
            <CFormLabel htmlFor="lastName">Last Name</CFormLabel>
            <CFormInput
              type="text"
              id="lastName"
              placeholder="Enter last Name here"
              required
              value={user.lastName}
              onChange={(e) =>
                dispatch(
                  set_user_field({ value: e.target.value, field: "lastName" })
                )
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <CFormLabel htmlFor="firstName">Email Address</CFormLabel>
            <CFormInput
              type="email"
              id="email"
              placeholder="Enter Email here"
              required
              value={user.email}
              onChange={(e) =>
                dispatch(
                  set_user_field({ value: e.target.value, field: "email" })
                )
              }
            />
          </div>
          <div className="col">
            <CFormLabel htmlFor="phoneNumber">Phone Number</CFormLabel>
            <CInputGroup className="mb-3">
              <CDropdown variant="input-group">
                <CDropdownToggle color="secondary" variant="outline">
                  +1
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem href="#">+91</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
              <CFormInput
                type="number"
                id="phoneNumber"
                placeholder="Enter Phone Number"
                required
                value={user.phoneNumber}
                maxLength={10}
                onChange={(e) =>
                  dispatch(
                    set_user_field({
                      value: e.target.value,
                      field: "phoneNumber",
                    })
                  )
                }
              />
            </CInputGroup>
          </div>
        </div>
        <div className="mb-3 col">
          <CFormLabel htmlFor="address">Roles</CFormLabel>

          {ROLES.map((role, index) => {
            return (
              <CFormSwitch
                key={index}
                label={role.label}
                id={role.value}
                defaultChecked={user.roles.includes(role.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(
                      set_user_field({
                        value: [...user.roles, role.value],
                        field: "roles",
                      })
                    );
                  } else {
                    dispatch(
                      set_user_field({
                        value: user.roles.filter((r) => r !== role.value),
                        field: "roles",
                      })
                    );
                  }
                }}

              />
            );
          })}
        </div>

        <CButton color="primary" type="submit">
          Create User
        </CButton>
      </CForm>
    </div>
  );
}

export default CreateUserForm;
