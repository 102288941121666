import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import ViewBookingContent from "../../../components/admin/Bookings/ViewBookingContent";

function ViewBooking({ visible, setVisible, data }) {
     return (
          <CModal
               size="xl"
               visible={visible}
               onClose={() => setVisible(preProps => { return { ...preProps, booking: false } })}
               backdrop="static"
          >
               <CModalHeader>
                    <CModalTitle>View Booking</CModalTitle>
               </CModalHeader>
               <CModalBody>
                <ViewBookingContent data={data}/>
               </CModalBody>
          </CModal>
     );
}

export default ViewBooking;