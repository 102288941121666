import React, { useRef } from "react";
import moment from "moment-timezone";
import { CSpinner } from "@coreui/react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { useSelector } from "react-redux";

function ListTimeSlotTemplate({ data, refetch, loading }) {
  const toastId = useRef(null);
  const timezone = useSelector((state) => state.timezone);

  const deleteTimeslot = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toastId.current = toast("Deleting Timeslot", {
          autoClose: false,
          isLoading: true,
        });
        const instance = await axiosInstance();
        instance
          .post("/timeslot/delete-config", { timeslotId: id })
          .then((res) => {
            if (res.data.status) {
              refetch();
              toast.dismiss(toastId.current);
              toast.success("Timeslot deleted", {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            console.log(id);
            console.log(error.response);
            return toast.update(toastId.current, {
              render: error?.response?.data?.message || "something went wrong",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              isLoading: false,
            });
          });
      }
    });
  };

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Sl.no</th>
          <th scope="col">Start Time</th>
          <th scope="col">End Time</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        {data?.timeslots?.map((timeSlot, i) => (
          <tr key={i}>
            <th>{timeSlot._id}</th>
            <td>
              {" "}
              {moment(timeSlot.startTime).tz(timezone).format("hh:mm a")}
            </td>
            <td> {moment(timeSlot.endTime).tz(timezone).format("hh:mm a")}</td>

            <td>
              <button
                class="btn btn-danger rounded-pill text-white"
                type="button"
                onClick={() => deleteTimeslot(timeSlot._id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>

      {loading && (
        <tr>
          <CSpinner color="primary" /> loading...
        </tr>
      )}
    </table>
  );
}

export default ListTimeSlotTemplate;
