import { CButton, CCard, CCardBody, CTableHead } from "@coreui/react";
import { Link } from "react-router-dom";
import { MdDateRange } from "react-icons/md";

const DashboardBookings = ({ data }) => {
  const currentDate = new Date().toISOString().split("T")[0];

  const count = data.reduce((acc, { createdAt }) => {
    if (createdAt.split("T")[0] === currentDate) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  return (
    <CCard className="container mt-3 mt-xl-5">
      <CCardBody>
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h1>Bookings</h1>
            <p>
              Total Bookings Today :<span className="fw-bold"> {count}</span>
            </p>
          </div>
          <div className="d-flex">
            <Link className="align-self-end" to="bookings/list">
              <CButton>View Bookings</CButton>
            </Link>
          </div>
        </div>
        <table className="table table-hover">
          <CTableHead color="light">
            <tr>
              <th scope="col">
                <span>
                  <MdDateRange />
                </span>
              </th>
              <th scope="col">Booking ID</th>
              <th scope="col">Status </th>
              <th scope="col">Package Name</th>
              <th scope="col">Amount</th>
            </tr>
          </CTableHead>

          <tbody>
            {data.slice(0, 5).map((booking, i) => (
              <tr key={i}>
                <td> {i + 1} </td>
                <td> {booking.bookingId} </td>
                <td> {booking.status} </td>

                <td>
                  {booking?.packageDetails?.packageName || "View Package"}{" "}
                </td>
                <td> CAD{booking.totalBookingAmountInCents} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  );
};

export default DashboardBookings;
