import firebase from "firebase/compat/app";
import "firebase/compat/auth";

//  web app's Firebase configuration

//staging firebase config
const stagingConfig = {
  apiKey: "AIzaSyAxgAE7VEe6XrDU0neFRfzwUYcMQbgHv3M",
  authDomain: "kivlik-a9f1d.firebaseapp.com",
  projectId: "kivlik-a9f1d",
  storageBucket: "kivlik-a9f1d.appspot.com",
  messagingSenderId: "58512900808",
  appId: "1:58512900808:web:6056b52556a42998201bfd"
};


//production firebase config
const productionConfig = {
  apiKey: "AIzaSyBuvSZL5x6rHF68HXb50kHygioj6iImEjA",
  authDomain: "kivlik-ca.firebaseapp.com",
  projectId: "kivlik-ca",
  storageBucket: "kivlik-ca.appspot.com",
  messagingSenderId: "545200870213",
  appId: "1:545200870213:web:adc400961c4180981e642c"
}

const firebaseConfig = 
  process.env.REACT_APP_ENV === "production" ?
    productionConfig : stagingConfig

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase