import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageName: "",
  description: "",
  serviceList: [
    {
      _id: 1,
      value: "Full Exterior Steam hand wash",
      label: "Full Exterior Steam hand wash",
    },
    {
      _id: 2,
      value: "Clean Rims, Tires, and Wheel Wells",
      label: "Clean Rims, Tires, and Wheel Wells",
    },
    { _id: 3, value: "Tire Dressing", label: "Tire Dressing" },
    {
      _id: 4,
      value: "Brush & Steam Clean Air Vents",
      label: "Brush & Steam Clean Air Vents",
    },
    {
      _id: 5,
      value: "Steam Clean door pockets & cup holders",
      label: "Steam Clean door pockets & cup holders",
    },
    {
      _id: 6,
      value: "Plastics/vinyl cleaning & polishing",
      label: "Plastics/vinyl cleaning & polishing",
    },
    {
      _id: 7,
      value: "Deep clean leather seats",
      label: "Deep clean leather seats",
    },
    {
      _id: 8,
      value: "Carpet mat & leather mat cleaning",
      label: "Carpet mat & leather mat cleaning",
    },
    {
      _id: 9,
      value: "Streak-free Shine window Steam cleaning",
      label: "Streak-free Shine window Steam cleaning",
    },
  ],
  includedServices: [],
  additionalServices: [],
  bodySpecificDetails: [],
};

export const editPackageSlice = createSlice({
  name: "editPackage",
  initialState,
  reducers: {
    set_default: (state, action) => {
      const {
        packageName,
        description,
        // includedServices,
        // additionalServices,
        // bodySpecificDetails,
      } = action.payload;

      state.packageName = packageName;
      state.description = description;
      // state.includedServices = includedServices;
      // state.additionalServices = additionalServices;
      // state.bodySpecificDetails = bodySpecificDetails;
    },
    set_packageName: (state, action) => {
      state.packageName = action.payload.packageName;
    },
    set_description: (state, action) => {
      state.description = action.payload.description;
    },
    set_includedServices: (state, action) => {
      state.includedServices = action.payload.includedServices;
    },
    set_additionalServices: (state, action) => {
      state.additionalServices = action.payload.additionalServices;
    },
    set_additionalAvailableServiceList: (state, action) => {
      state.additionalAvailableServiceList =
        action.payload.additionalAvailableServiceList;
    },
    set_bodySpecificDetails: (state, action) => {
      state.bodySpecificDetails = action.payload;
    },
    set_serviceList: (state, action) => {
      state.serviceList = action.payload;
    },
    reset_package: (state, action) => {
      state.packageName = "";
      state.description = "";
      // state.serviceList = initialState.serviceList
      state.includedServices = [];
      state.additionalServices = [];
      state.bodySpecificDetails = [];
    },
  },
});

export const {
    set_default,
  set_serviceList,
  set_packageName,
  set_description,
  set_includedServices,
  set_additionalServices,
  set_additionalAvailableServiceList,
  set_bodySpecificDetails,
  reset_package,
} = editPackageSlice.actions;

export default editPackageSlice.reducer;
