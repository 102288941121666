import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const BookingsChart = ({ data, packageData }) => {
  const [packageInfo, setPackageInfo] = useState([]);

  useEffect(() => {
    const newLimit = data.map((booking) => {
      return {
        date: booking.createdAt,
        packageName: booking.packageDetails.packageName,
      };
    });
    setPackageInfo(newLimit);
  }, [data]);

  const countByDate = packageInfo.reduce((countMap, obj) => {
    const date = obj.date.split("T")[0];
    const key = `${date}-${obj.packageName}`;

    if (countMap[key]) {
      countMap[key]++;
    } else {
      countMap[key] = 1;
    }

    return countMap;
  }, {});

  const packageCountsByDate = Object.entries(countByDate).map(
    ([key, count]) => {
      const [date, packageName] = [key.slice(0, 10), key.slice(11)];

      for (let i = 0; i < packageData.length; i++) {
        if (packageName === packageData[i].packageName) {
          return {
            name: date,
            [packageData[i].packageName]: count,
          };
        }
      }
      return 0;
    }
  );

  const mergedData = Object.values(
    packageCountsByDate.reduce((acc, obj) => {
      const { name, ...rest } = obj;
      if (!acc[name]) {
        acc[name] = { name, ...rest };
      } else {
        acc[name] = { ...acc[name], ...rest };
      }
      return acc;
    }, {})
  );

  console.log(mergedData);

  const colorCodes = ["#ff7f0e", "#52514f", "#1f6acc", "#50C878"];
  return (
    <div className="w-100" style={{ height: 450 }}>
      <ResponsiveContainer>
        <BarChart data={mergedData.slice(0, 6)} layout="vertical">
          <XAxis type="number" />
          <YAxis width={90} dataKey="name" type="category" />
          <Tooltip />
          <Legend />
          {packageData.map((item, i) => (
            <Bar dataKey={item.packageName} fill={colorCodes[i]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BookingsChart;
