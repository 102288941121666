import {
  BodyTypeChart,
  BookingsChart,
  CountryStats,
  PriceChart,
} from "./Charts/";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";

const Charts = ({ userData, bookingsData, packageData }) => {
  return (
    <div>
      <PriceChart data={bookingsData} />
      <div className="d-flex mt-5">
        <CCard className="col">
          <CCardHeader>
            <h5 className="text-center mt-2">Country Wise User Stats</h5>
          </CCardHeader>
          <CCardBody className="chart">
            <CountryStats data={userData} />
          </CCardBody>
        </CCard>
        <CCard className="col">
          <CCardHeader>
            <h5 className="text-center mt-2">Booked Packages</h5>
          </CCardHeader>
          <CCardBody className="chart">
            <BookingsChart data={bookingsData} packageData={packageData} />
          </CCardBody>
        </CCard>
        <CCard className="col">
          <CCardHeader>
            <h5 className="text-center mt-2">BodyTypes Booked</h5>
          </CCardHeader>
          <CCardBody className="chart">
            <BodyTypeChart data={bookingsData} />
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default Charts;
