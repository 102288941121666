import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CreatePackageForm from "../../../components/admin/packages/CreatePackageForm/CreatePackageForm";

function CreatePackage() {
  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emphasis">Create Package</div>
            </CCol>
          </CRow>
            <CreatePackageForm />
        </CCardBody>
      </CCard>
    </>
  );
}

export default CreatePackage;
