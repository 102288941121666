import { useEffect, useRef, useState } from "react";
import { CButton, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../utils/axiosInstance";
import CreateTimeslotForm from "../../../components/admin/timeslots/CreateTimeslot/CreateTimeslotForm";
import ListTimeSlotTemplate from "../../../components/admin/timeslots/CreateTimeslot/ListTimeSlotTemplates";
import DayCount from "../../../components/admin/timeslots/CreateTimeslot/DayCount";

function CreateTimeslot() {
  const [timeSlotData, setTimeSloteData] = useState({});
  const [dayCount, setDayCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableDayCountButton, setDisableDayCountButton] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    (async () => {
      setLoading(true);
      const instance = await axiosInstance();
      const { data } = await instance.get("/timeslot/config");
      setTimeSloteData(data.data);
      setDayCount(data?.data?.timeslotsDayCount);
      setLoading(false);
    })();
  };

  const generateTimeslotManually = async () => {
    toastId.current = toast(`Generating timeslots for ${dayCount || ""}`, {
      autoClose: false,
      isLoading: true,
    });
    const instance = await axiosInstance();
    instance
      .post("/timeslot/generate-manuall-timeslot")
      .then((res) => {
        toast.update(toastId.current, {
          render: res?.data?.message || "timeslots generated",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          isLoading: false,
        });
      })
      .catch((err) => {
        return toast.update(toastId.current, {
          render: err?.response?.data?.message || "something went wrong",
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          isLoading: false,
        });
      });
  };
  const toastId = useRef(null);
  const deleteAllTimeslots = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toastId.current = toast("Deleting all timeslots", {
          autoClose: false,
          isLoading: true,
        });
        const instance = await axiosInstance();
        instance
          .delete("/timeslot/delete-all-timeslot")
          .then((res) => {
            if (res.data.status) {
              toast.update(toastId.current, {
                render: res?.data?.message || "All timeslots deleted",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
            return toast.update(toastId.current, {
              render: error?.response?.data?.message || "something went wrong",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              isLoading: false,
            });
          });
      }
    });
  };

  return (
    <>
      <div className="dflex justify-content-space-evenly">
        <CCard className="mb-4 col-md-8">
          <CCardBody>
            <CRow>
              <CCol sm={12}>
                <h4 className="card-title mb-0">KIVLIK</h4>
                <div className="fs-6 mb-5">Configurate Timeslot</div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm={12}>
                <CreateTimeslotForm setTimeSloteData={setTimeSloteData} />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard className="mb-4 col-md-2">
          <CCardBody>
            <CRow>
              <CCol className="dflex align_item_center">
                <DayCount
                  timeSlotData={timeSlotData}
                  setTimeSloteData={setTimeSloteData}
                  isDisable={disableDayCountButton}
                  fetchData={fetchData}
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard className="mb-4 col-md-2">
          <CCardBody className="dflex flex-column justify-content-center align-items-center">
            <CRow>
              <CCol className="col-md-11">
                <CButton color="primary" onClick={generateTimeslotManually}>
                  Create timeslot for {dayCount || ""} days
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CButton
                  className="col-md-11 mt-4"
                  color="primary"
                  onClick={deleteAllTimeslots}
                >
                  Delete all timeslots
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>

      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol className="dflex align_item_center">
              <ListTimeSlotTemplate
                data={timeSlotData}
                refetch={fetchData}
                loading={loading}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
}

export default CreateTimeslot;
