import React, { useEffect, useRef, useState } from "react";
import {
  CForm,
  CButton,
  CFormLabel,
  CFormInput,
  CFormTextarea,
} from "@coreui/react";
// import SelectIncludedServices from "./SelectIncludedServices";
import { useSelector, useDispatch } from "react-redux";
import BodySpecificPriceAndDuration from "./BodySpecificPriceAndDuration";
// import SelectAdditionalService from "./SelectAdditionalService";
// import { useQuery } from "react-query";
// import { getAllServices } from "../../../../utils/query/serviceQuery";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../utils/axiosInstance";
// import { useNavigate } from "react-router";
import {
  set_default,
  set_packageName,
  set_description,
  // set_serviceList,
  reset_package,
  set_includedServices,
  set_additionalServices,
  set_serviceList,
} from "../../../../Redux/package/editPackageSlice";
import SelectServiceTable from "./SelectServiceTable";
import { getAllServices } from "../../../../utils/query/serviceQuery";
import { useQuery } from "react-query";

function EditPackageForm({ toggleModal, oldPackageData }) {
  // const navigate = useNavigate();
  const toastId = useRef(null);
  const notifyToast = (message) =>
    (toastId.current = toast(message, { autoClose: false, isLoading: true }));
  const updateToast = () =>
    toast.update(toastId.current, {
      render: "Package Edited",
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      isLoading: false,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const updateToastError = (message) =>
    toast.update(toastId.current, {
      render: message,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      isLoading: false,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const dispatch = useDispatch();
  const {
    packageName,
    description,
    bodySpecificDetails,
    includedServices,
    additionalServices,
  } = useSelector((state) => state.editPackage);
  // const toastId = useRef(null);
  const [validated, setValidated] = useState(false);

  //fething service over....

  useEffect(() => {
    dispatch(set_default(oldPackageData));
    // eslint-disable-next-line
  }, [oldPackageData]);

  const submitForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      notifyToast("Processing Request...");
      let packagePrices = oldPackageData.packagePrices;
      let durations = oldPackageData.durations;
      const idOfIncludedServices = includedServices.map((x) => x._id);
      const idOfAdditionalServices = additionalServices.map((x) => x._id);
      if (bodySpecificDetails.length) {
        packagePrices = [];
        durations = [];
      }
      for (let i = 0; i < bodySpecificDetails.length; i++) {
        const { _id, isActive, price, salePrice, duration } =
          bodySpecificDetails[i];

        if (isActive) {
          console.log(isActive);
          packagePrices.push({
            bodytypeId: _id,
            priceInCents: parseInt(price) * 100,
            salePriceInCents: parseInt(salePrice) * 100,
          });
          durations.push({
            bodytypeId: _id,
            durationInMin: parseInt(duration),
          });
        }
      }
      //TODO: post below data to server
      console.log({
        packageId: oldPackageData._id,
        packageName,
        description,
        packagePrices,
        durations,
        includedServices: idOfIncludedServices,
        additionalServices: idOfAdditionalServices,
      });
      const instance = await axiosInstance();
      instance
        .post("/package/edit", {
          packageId: oldPackageData._id,
          packageName,
          description,
          packagePrices,
          durations,
          includedServices: idOfIncludedServices,
          additionalServices: idOfAdditionalServices,
        })
        .then((res) => {
          if (res.data.status) {
            updateToast();
            dispatch(reset_package());
            dispatch(set_includedServices({ includedServices: [] }));
            dispatch(set_additionalServices({ additionalServices: [] }));
            toggleModal(false);
            // navigate({ pathname: "/admin/packages/list" });
          }
        })
        .catch((err) => {
          console.log(err);
          if (!err.response.data.status) {
            updateToastError(
              err.response.data.message || "something went wrong"
            );
          }
        });
    }
    setValidated(true);
  };
  const { data, status } = useQuery("getServices", getAllServices);
  useEffect(() => {
    if (status === "loading") {
      return notifyToast("Services is fetching");
    }
    const serviceData = data?.data?.data;
    if (status === "success") {
      const exteriorServices = serviceData.filter((x) => x.type === "exterior");
      const interiorServices = serviceData.filter((x) => x.type === "interior");
      const _filteredInternalServices = interiorServices.map(
        ({ _id, title, type }) => {
          return { _id, value: title, label: title, type };
        }
      );
      const _filteredExternalServices = exteriorServices.map(
        ({ _id, title, type }) => {
          return { _id, value: title, label: title, type };
        }
      );
      updateToast();
      dispatch(
        set_serviceList([
          {
            label: "Interior Services",
            options: [..._filteredInternalServices],
          },
          {
            label: "External Services",
            options: [..._filteredExternalServices],
          },
        ])
      );
    }
    // dispatch(set_serviceList(data?.data?.data))
    //eslint-disable-next-line
  }, [data, status]);

  return (
    <div>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="my-3">
          <CFormLabel htmlFor="packageName">Package Name</CFormLabel>
          <CFormInput
            type="text"
            id="packageName"
            placeholder="Enter Package Name"
            value={packageName}
            required
            onChange={(e) =>
              dispatch(set_packageName({ packageName: e.target.value }))
            }
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="description">Description</CFormLabel>
          <CFormTextarea
            id="description"
            rows="3"
            required
            value={description}
            onChange={(e) =>
              dispatch(set_description({ description: e.target.value }))
            }
          ></CFormTextarea>
        </div>

        <CFormLabel>beta- Select Service</CFormLabel>
        <SelectServiceTable oldPackageData={oldPackageData} />

        {/* <CFormLabel>Included Services</CFormLabel>
        <SelectIncludedServices
          oldIncludedServices={oldPackageData.includedServices}
        />

        <CFormLabel className="mt-3">Additional Services</CFormLabel>
        <SelectAdditionalService
          oldAdditionalServices={oldPackageData.additionalServices}
        /> */}

        <CFormLabel>Package Price and Duration</CFormLabel>
        <BodySpecificPriceAndDuration
          oldPackagePrices={oldPackageData.packagePrices}
          oldDurations={oldPackageData.durations}
        />

        <CButton className="mt-3 col-12" color="primary" type="submit">
          Edit package
        </CButton>
      </CForm>
    </div>
  );
}

export default EditPackageForm;
