import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName:"",
  lastName:"",
  email: "",
  phoneNumber:"",
  address:[],
  roles:["customer"]
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set_user_field: (state, action) => {
      const {value, field} = action.payload;
      state[field] = value;
    },
    reset_service:(state)=>{
      state = initialState
    }
  },
});

export const { set_user_field ,reset_service } = userSlice.actions;

export default userSlice.reducer;
