import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import UserProfile from "../../../components/admin/Users/UserProfile";

function ShowDetails({ visible, setVisible, data, setData }) {
  return (
    <CModal
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>User profile</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <UserProfile data={data} setData={setData} setVisible={setVisible} />
      </CModalBody>
    </CModal>
  );
}

export default ShowDetails;
