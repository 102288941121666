import React from 'react'
import CreateServiceForm from '../../../components/admin/services/CreateServiceForm/CreateServiceForm'
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";

function CreateService() {
    return (
        <>
        <CCard className="mb-4">
          <CCardBody>
            <CRow>
              <CCol sm={5}>
                <h4 className="card-title mb-0">
                  KIVLIK
                </h4>
                <div className="small text-medium-emphasis mb-5">
                  Create Services
                </div>
              </CCol>
            </CRow>
            <CRow>
                <CCol>
            <CreateServiceForm />
            </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </>    )
}

export default CreateService
