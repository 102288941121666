import { CButton, CCard, CCardBody, CTableHead } from "@coreui/react";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";

const DashboardUsers = ({ data }) => {
  const currentDate = new Date().toISOString().split("T")[0];

  const count = data.reduce((acc, { createdAt }) => {
    if (createdAt.split("T")[0] === currentDate) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  return (
    <CCard className="container mt-5">
      <CCardBody>
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h1>Users</h1>
            <p>
              Users Registered Today : <span className="fw-bold"> {count}</span>
            </p>
          </div>
          <div className="d-flex">
            <Link className="align-self-end" to="user/list">
              <CButton>List Users</CButton>
            </Link>
          </div>
        </div>

        <table class="table table-hover">
          <CTableHead color="light">
            <tr>
              <th scope="col">
                <span>
                  <FiUser />
                </span>
              </th>
              <th scope="col">Full name </th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
            </tr>
          </CTableHead>
          <tbody>
            {data
              .slice(0, 5)
              .sort()
              .map((user, i) => {
                return (
                  <tr key={user._id}>
                    <td>{i + 1}</td>
                    <td> {`${user.firstName} ${user.lastName}`}</td>
                    <td> {user.email}</td>
                    <td> {user.phoneNumber}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  );
};

export default DashboardUsers;
