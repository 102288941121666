import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import DetailOfTimeSlotForm from "../../../components/admin/timeslots/DetailOfTimeSlot";

function DetailOfTimeSlot({ setVisible, visible, timeSlotData, refetch, setTimeSlotData }) {

     return (
          <CModal
               size="xl"
               visible={visible}
               onClose={() => setVisible(false)}
               backdrop="static"
          >
               <CModalHeader>
                    <CModalTitle> Avaliable Timeslots</CModalTitle>
               </CModalHeader>
               <CModalBody>
                    <DetailOfTimeSlotForm setTimeSlotData={setTimeSlotData} refetch={refetch} timeSlotData={timeSlotData} />

               </CModalBody>
          </CModal>
     )
}

export default DetailOfTimeSlot