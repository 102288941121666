import React, { useState } from "react";
import { CForm, CFormLabel, CButton, CFormInput } from "@coreui/react";
import { toast } from "react-toastify";
import "rc-time-picker/assets/index.css";
import { axiosInstance } from "../../../utils/axiosInstance";

function EditVanDetailsModal({ toggleModal, data, refetch }) {
  const INITIAL_VAN_STATE = {
    kNumber: data.kNumber,
    city: data.city,
    numberPlate: data.numberPlate,
  };

  const [vanDetials, setVanDetails] = useState(INITIAL_VAN_STATE);

  const [validated, setValidated] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    if (!vanDetials.kNumber) return toast.warning("KIVLIK-Number is required");
    if (!vanDetials.city) return toast.warning("city name is required");
    if (!vanDetials.numberPlate)
      return toast.warning("number  plate  is required");
    if (INITIAL_VAN_STATE === vanDetials) return;

    try {
      const instance = await axiosInstance();
      const { data: resultData } = await instance.post("/van/edit", {
        vanId: data._id,
        ...vanDetials,
      });

      if (resultData.status) {
        toast.success("Van details is Edited", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        refetch();
        toggleModal(false);
      }
    } catch (err) {
      console.log(err.response.data);
      if (!err.response.data.status) {
        toast.error(err.response.data.message || "something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setValidated(true);
  };

  return (
    <div>
      <CForm
        className="row g-3 needs-validation mx-auto"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">kNumber</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, kNumber: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.kNumber}
          />
        </div>
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">City</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, city: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.city}
          />
        </div>

        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">Number Plate</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, numberPlate: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.numberPlate}
          />
        </div>

        <CButton color="primary" type="submit">
          Update Van
        </CButton>
      </CForm>
    </div>
  );
}

export default EditVanDetailsModal;
