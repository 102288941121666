import React, { useEffect, useRef, useState } from "react";
import {
  CForm,
  CButton,
  CFormLabel,
  CFormInput,
  CFormTextarea,
} from "@coreui/react";
// import SelectIncludedServices from "./SelectIncludedServices";
import { useSelector, useDispatch } from "react-redux";
import {
  reset_package,
  set_description,
  set_packageName,
  set_serviceList,
} from "../../../../Redux/package/createPackageSlice";
import BodySpecificPriceAndDuration from "./BodySpecificPriceAndDuration";
// import SelectAdditionalService from "./SelectAdditionalService";
import { useQuery } from "react-query";
import { getAllServices } from "../../../../utils/query/serviceQuery";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../utils/axiosInstance";
import {  useNavigate } from "react-router";
import SelectServiceTable from "./SelectServiceTable";
import { set_additionalServices, set_includedServices } from "../../../../Redux/package/createPackageSlice";


function CreatePackageForm() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {
    packageName,
    description,
    bodySpecificDetails,
    includedServices,
    additionalServices,
  } = useSelector((state) => state.createPackage);
  const toastId = useRef(null);
  const [validated, setValidated] = useState(false);
  const { data, status } = useQuery("getServices", getAllServices);
  const notifyToast = (message) => toastId.current = toast(message, { autoClose: false , isLoading:true});
  const updateToast = () => toast.update(toastId.current, {render:"Services Fetched sucessfully", type: toast.TYPE.SUCCESS, autoClose: 5000 , isLoading:false});
  useEffect(() => {
    if(status === "loading"){
      return notifyToast("Services is fetching")
    }
    const serviceData = data?.data?.data;
    console.log(serviceData);
    if (status === "success") {
      const exteriorServices = serviceData.filter((x) => x.type === "exterior");
      const interiorServices = serviceData.filter((x) => x.type === "interior");
      console.log({ exteriorServices, interiorServices });
      const _filteredInternalServices = interiorServices.map(
        ({ _id, title, type }) => {
          return { _id, value: title, label: title ,type};
        }
      );
      const _filteredExternalServices = exteriorServices.map(
        ({ _id, title,type }) => {
          return { _id, value: title, label: title ,type};
        }
      );
      updateToast()
      dispatch(
        set_serviceList([
          {
            label: "Interior Services",
            options: [..._filteredInternalServices],
          },
          {
            label: "External Services",
            options: [..._filteredExternalServices],
          },
        ])
      );
    }
    // dispatch(set_serviceList(data?.data?.data))
    //eslint-disable-next-line
  }, [data, status]);

  const submitForm =async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let packagePrices = [];
      let durations = [];
      const idOfIncludedServices = includedServices.map((x) => x._id);
      const idOfAdditionalServices = additionalServices.map((x) => x._id);
      for (let i = 0; i < bodySpecificDetails.length; i++) {
        const { _id, isActive, price,salePrice, duration } = bodySpecificDetails[i];

        if (isActive) {
          console.log("pushing ", i);
          packagePrices.push({
            bodytypeId: _id,
            priceInCents: parseInt(price) * 100,
            salePriceInCents: parseInt(salePrice) * 100,
          });
          durations.push({
            bodytypeId: _id,
            durationInMin: parseInt(duration),
          });
        }
      }

      //TODO: post below data to server
      console.log({
        title: packageName,
        description,
        packagePrices,
        durations,
        includedServices: idOfIncludedServices,
        additionalServices: idOfAdditionalServices,
      });
      const instance = await axiosInstance();
      instance
        .post("/package/add", {
          packageName,
          description,
          packagePrices,
          durations,
          includedServices: idOfIncludedServices,
          additionalServices: idOfAdditionalServices,
        })
        .then((res) => {
          if (res.data.status) {
            toast.success("Package is Created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })

            dispatch(reset_package())
            dispatch(set_includedServices({
              includedServices: [],
            }))
            dispatch(set_additionalServices({
              additionalServices: []
            }))
            navigate({pathname:"/admin/packages/list"})
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          if (!err.response.data.status) {
            toast.error(err.response.data.message || "something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
    setValidated(true);
  };

  return (
    <div>
      <CForm
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
        onSubmit={submitForm}
      >
        <div className="my-3">
          <CFormLabel htmlFor="packageName">Package Name</CFormLabel>
          <CFormInput
            type="text"
            id="packageName"
            placeholder="Enter Package Name"
            value={packageName}
            required
            onChange={(e) =>
              dispatch(set_packageName({ packageName: e.target.value }))
            }
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="description">Description</CFormLabel>
          <CFormTextarea
            id="description"
            rows="3"
            required
            value={description}
            onChange={(e) =>
              dispatch(set_description({ description: e.target.value }))
            }
          ></CFormTextarea>
        </div>
        <CFormLabel>Beta - Services</CFormLabel>
        <SelectServiceTable />

        {/* <CFormLabel>Included Services</CFormLabel>
        <SelectIncludedServices />

        <CFormLabel className="mt-3">Additional Services</CFormLabel>
        <SelectAdditionalService /> */}

        <CFormLabel>Package Price and Duration</CFormLabel>
        <BodySpecificPriceAndDuration />

        <CButton className="mt-3 col-12" color="primary" type="submit">
          Create package
        </CButton>
      </CForm>
    </div>
  );
}

export default CreatePackageForm;
