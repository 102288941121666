import React from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import EditPackageForm from "../../../components/admin/packages/EditPackageForm/EditPackageForm";

function EditPackage({ visible, setVisible, data }) {
  return (
    <CModal
      size="xl"
      visible={visible}
      onClose={() => setVisible(false)}
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle>Edit {data.packageName} package</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <EditPackageForm toggleModal={setVisible} oldPackageData={data} />
      </CModalBody>
    </CModal>
  );
}

export default EditPackage;
