import React, { useState } from "react";
// import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import {
  CForm,
  CSpinner,
  CFormLabel,
  CButton,
  CFormInput,
} from "@coreui/react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { useNavigate } from "react-router";

const INITIAL_VAN_STATE = {
  kNumber: "",
  city: "",
  numberPlate: "",
};

function CreateVanFrom() {
  const navigate = useNavigate();
  const [vanDetials, setVanDetails] = useState(INITIAL_VAN_STATE);
  const [loading, setLoading] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (!vanDetials.kNumber) return toast.warning("KIVLIK-Number is required");
    if (!vanDetials.city) return toast.warning("city name is required");
    if (!vanDetials.numberPlate)
      return toast.warning("number  plate  is required");
    setLoading(true);

    const instance = await axiosInstance();
    instance
      .post("/van/add", vanDetials)
      .then((res) => {
        setLoading(false);
        toast.success("Van is Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate({ pathname: "/admin/Vans/list" });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message || "something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <CForm
        className="row g-3 needs-validation mx-auto"
        //    noValidate
        //    validated={validated}
        onSubmit={submitForm}
      >
        <div className="mb-3  row col-md-4">
          <CFormLabel htmlFor="serviceName">KIVLIK Number</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, kNumber: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.kNumber}
          />
        </div>
        {/* city of the van  */}
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">City</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, city: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.city}
          />
        </div>
        {/* vehile number palte */}
        <div className="mb-3 row col-md-4">
          <CFormLabel htmlFor="serviceName">Number plate</CFormLabel>
          <CFormInput
            type="text"
            style={{ width: "96%" }}
            onChange={(e) =>
              setVanDetails((preState) => {
                return { ...preState, numberPlate: e.target.value };
              })
            }
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={vanDetials.numberPlate}
          />
        </div>

        <CButton disabled={loading} color="primary" type="submit">
          {loading ? <CSpinner /> : "Create New Van"}
        </CButton>
      </CForm>
    </>
  );
}

export default CreateVanFrom;
