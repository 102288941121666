import { CSpinner, CButton, CPagination, CPaginationItem } from "@coreui/react";
import moment from "moment-timezone";
import React from "react";
import { useQuery } from "react-query";
import { getAllBookings } from "../../../utils/query/bookings";
import { useSelector } from "react-redux";

function ListBookingTable({
  setVisible,
  setVehicle,
  setAddress,
  setPackage,
  setBookingData,
}) {
  const { data, status } = useQuery("getAllBookings", getAllBookings);
  const timezone = useSelector((state) => state.timezone);

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sl.no</th>
            <th scope="col"> Booking ID </th>
            <th scope="col"> Status </th>
            <th scope="col">Address</th>
            {/* <th scope="col">Vehicle</th> */}
            <th scope="col">Job time</th>
            <th scope="col">Van</th>
            <th scope="col">Package Selected</th>
            <th scope="col">Amount</th>
            <th scope="col">View</th>
          </tr>
        </thead>

        <tbody>
          {status !== "loading" &&
            data?.data?.data.map((booking, i) => (
              <tr
                onClick={() => console.log(booking)}
                style={{ cursor: "pointer" }}
              >
                <td> {i + 1} </td>
                <td> {booking.bookingId} </td>
                <td> {booking.status} </td>
                <td>
                  {" "}
                  <CButton
                    color="primary"
                    onClick={() => {
                      setAddress(booking.address);
                      setVisible((preProps) => {
                        return { ...preProps, address: true };
                      });
                    }}
                  >
                    {" "}
                    Address{" "}
                  </CButton>{" "}
                </td>
                {/* <td>  <CButton color="primary" onClick={() => { setVehicle(booking.vehicle); setVisible(preProps => { return { ...preProps, vehicle: true } }) }}> Vehicle  </CButton>  </td> */}
                <td>
                  {" "}
                  {moment
                    .tz(booking.timeslot.startTime, timezone)
                    .format("hh:mm a")}{" "}
                  <b style={{ color: "green" }}> to </b>{" "}
                  {moment
                    .tz(booking.timeslot.endTime, timezone)
                    .format("hh:mm a")}{" "}
                </td>
                <div>{booking?.van?.kNumber || "Not Assigned"}</div>
                <td>
                  {" "}
                  <CButton
                    color="primary"
                    onClick={() => {
                      setPackage(booking?.packageDetails);
                      setVisible((preProps) => {
                        return { ...preProps, package: true };
                      });
                    }}
                  >
                    {booking?.packageDetails?.packageName || "View Package"}{" "}
                  </CButton>{" "}
                </td>
                <td> CAD {booking.totalBookingAmountInCents} </td>
                <td>
                  {" "}
                  <CButton
                    color="primary"
                    onClick={() => {
                      setBookingData(booking);
                      setVisible((preProps) => {
                        return { ...preProps, booking: true };
                      });
                    }}
                  >
                    View
                  </CButton>{" "}
                </td>
              </tr>
            ))}
        </tbody>

        {status === "loading" && (
          <tbody>
            <tr>
              <CSpinner color="primary" />
              loading....
            </tr>
          </tbody>
        )}
      </table>
      <CPagination size="sm" aria-label="Page navigation example">
        <CPaginationItem>Previous</CPaginationItem>
        <CPaginationItem>1</CPaginationItem>
        <CPaginationItem>2</CPaginationItem>
        <CPaginationItem>3</CPaginationItem>
        <CPaginationItem>Next</CPaginationItem>
      </CPagination>
    </>
  );
}

export default ListBookingTable;
