import React from 'react'
import {Link} from "react-router-dom"
function LandingPage() {

    return (
        <div>
            <h1>LandingPage</h1>
            <Link to="/signin" >SIGN IN</Link> &nbsp;
            <Link to="/signup" >SIGN UP</Link>
        </div>
    )
}

export default LandingPage
