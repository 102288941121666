import {
  CFormSwitch,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect } from "react";
// import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  set_additionalServices,
  set_includedServices,
} from "../../../../Redux/package/createPackageSlice";

function SelectServiceTable() {
  const dispatch = useDispatch();
  const { serviceList, includedServices, additionalServices } = useSelector(
    (state) => state.createPackage
  );
  useEffect(() => {
    console.log({ serviceList, includedServices, additionalServices });
  }, [serviceList, includedServices, additionalServices]);

  
  return (
    <div>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            <CTableHeaderCell scope="col">Service Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Included</CTableHeaderCell>
            <CTableHeaderCell scope="col">Additional</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow>
            <CTableHeaderCell scope="row"></CTableHeaderCell>
            <CTableDataCell><b>Interior Services</b>
            </CTableDataCell>
          </CTableRow>
          {serviceList.length > 0 &&
            serviceList[0].options?.map((service, index) => {
              let isIncluded,
                isAdditional = false;

              if (includedServices.length > 0) {
                isIncluded = includedServices?.find(
                  (x) => x._id === service._id
                );
                isAdditional = additionalServices?.find(
                  (x) => x._id === service._id
                );
              }

              return (
                <CTableRow key={service._id}>
                  <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                  <CTableDataCell>{service.label}</CTableDataCell>
                  <CTableDataCell>
                    <CFormSwitch
                      id="IncludedServices"
                      checked={isIncluded}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            set_includedServices({
                              includedServices: [...includedServices, service],
                            })
                          );
                          dispatch(
                            set_additionalServices({
                              additionalServices: additionalServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            set_includedServices({
                              includedServices: includedServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        }
                      }}
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <CFormSwitch
                      id="AdditionalServices"
                      checked={isAdditional}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            set_additionalServices({
                              additionalServices: [
                                ...additionalServices,
                                service,
                              ],
                            })
                          );
                          dispatch(
                            set_includedServices({
                              includedServices: includedServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            set_additionalServices({
                              additionalServices: additionalServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        }
                      }}
                    />
                  </CTableDataCell>
                </CTableRow>
              );
            })}
          <CTableRow>
            <CTableHeaderCell scope="row"></CTableHeaderCell>
            <CTableDataCell>
              <b>Exterior Services</b>
            </CTableDataCell>
          </CTableRow>
          {serviceList.length > 0 &&
            serviceList[1].options?.map((service, index) => {
              let isIncluded,
                isAdditional = false;

              if (includedServices.length > 0) {
                isIncluded = includedServices?.find(
                  (x) => x._id === service._id
                );
                isAdditional = additionalServices?.find(
                  (x) => x._id === service._id
                );
              }
              return (
                <CTableRow key={service._id}>
                  <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                  <CTableDataCell>{service.label}</CTableDataCell>
                  <CTableDataCell>
                    <CFormSwitch
                      id="IncludedServices"
                      checked={isIncluded}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            set_includedServices({
                              includedServices: [...includedServices, service],
                            })
                          );
                          dispatch(
                            set_additionalServices({
                              additionalServices: additionalServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            set_includedServices({
                              includedServices: includedServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        }
                      }}
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <CFormSwitch
                      id="AdditionalServices"
                      checked={isAdditional}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            set_additionalServices({
                              additionalServices: [
                                ...additionalServices,
                                service,
                              ],
                            })
                          );
                          dispatch(
                            set_includedServices({
                              includedServices: includedServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            set_additionalServices({
                              additionalServices: additionalServices.filter(
                                (x) => x._id !== service._id
                              ),
                            })
                          );
                        }
                      }}
                    />
                  </CTableDataCell>
                </CTableRow>
              );
            })}
        </CTableBody>
      </CTable>
    </div>
  );
}

export default SelectServiceTable;
