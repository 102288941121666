import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortablePackageTable = ({ packageItem, i, editService, refetch }) => {
  const timezone = useSelector((state) => state.timezone);

  const { packageName, description, createdAt, updatedAt, _id } = packageItem;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: packageItem, cancel: "button" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deletePackage = (packageId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const instance = await axiosInstance();
        instance
          .post(`/package/delete`, {
            packageId,
          })
          .then((res) => {
            refetch();
            if (res.data.status) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  return (
    <tr ref={setNodeRef} style={style}>
      <th scope="row" {...attributes} {...listeners}>
        {i + 1}
      </th>
      <td {...attributes} {...listeners}>
        {packageName}
      </td>
      <td {...attributes} {...listeners}>
        {description.substring(0, 30)}
        {description.length > 30 ? "....." : ""}
      </td>
      <td {...attributes} {...listeners}>
        {moment.tz(createdAt, timezone).format("DD MMM h:mm a")}
      </td>
      <td {...attributes} {...listeners}>
        {moment.tz(updatedAt, timezone).format("DD MMM h:mm a")}
      </td>
      <td>
        <button
          className="btn btn-warning rounded-pill"
          onClick={() => {
            editService(packageItem);
          }}
          type="button"
        >
          Edit
        </button>
      </td>
      <td>
        <button
          class="btn btn-danger rounded-pill"
          type="button"
          onClick={() => {
            deletePackage(_id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default SortablePackageTable;
