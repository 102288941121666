import React, { useRef, useState, useEffect } from "react";
import ListPackageTable from "../../../components/admin/packages/ListPackageTable/ListPackageTable";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import EditPackage from "./EditPackage";
import { set_serviceList } from "../../../Redux/package/editPackageSlice";
import { useQuery } from "react-query";
import { getAllServices } from "../../../utils/query/serviceQuery";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/axiosInstance";

function ListPackages() {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState(false);
  const [sortedPackages, setSortedPackages] = useState([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const editService = (data) => {
    console.log("old package data", data);
    setEditData(data);
    setVisible(true);
  };
  const { data, status } = useQuery("getServices", getAllServices);

  // fetching service and updatin on serviveList redux
  const notifyToast = (message) =>
    (toastId.current = toast(message, { autoClose: false, isLoading: true }));
  const updateToast = () =>
    toast.update(toastId.current, {
      render: "Services Fetched sucessfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      isLoading: false,
    });
  useEffect(() => {
    if (status === "loading") {
      return notifyToast("Services is fetching");
    }
    const serviceData = data?.data?.data;
    console.log(serviceData);
    if (status === "success") {
      const exteriorServices = serviceData.filter((x) => x.type === "exterior");
      const interiorServices = serviceData.filter((x) => x.type === "interior");
      console.log({ exteriorServices, interiorServices });
      const _filteredInternalServices = interiorServices.map(
        ({ _id, title, type }) => {
          return { _id, value: title, label: title, type };
        }
      );
      const _filteredExternalServices = exteriorServices.map(
        ({ _id, title, type }) => {
          return { _id, value: title, label: title, type };
        }
      );
      updateToast();
      dispatch(
        set_serviceList([
          {
            label: "Interior Services",
            options: [..._filteredInternalServices],
          },
          {
            label: "External Services",
            options: [..._filteredExternalServices],
          },
        ])
      );
    }
    // dispatch(set_serviceList(data?.data?.data))
    //eslint-disable-next-line
  }, [data, status]);

  const changePackageOrder =async() => {
    const instance = await axiosInstance();
    instance
      .put(`/package/edit-sequence`, {
        sequencePackage: sortedPackages,
      })
      .then((res) => {
        toast.success("Packages Rearranged!");
        setShowSaveBtn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={6}>
              <h4 id="traffic" className="card-title mb-0">
                KIVLIK
              </h4>
              <div className="small text-medium-emphasis">Package Lists</div>
            </CCol>
            <CCol sm={6} className="text-end">
              {showSaveBtn && (
                <button
                  className="btn btn-primary rounded-pill"
                  onClick={changePackageOrder}
                  type="button"
                >
                  Save
                </button>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ListPackageTable
                edited={!visible}
                editService={editService}
                setSortedPackages={setSortedPackages}
                setShowSaveBtn={setShowSaveBtn}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <EditPackage visible={visible} setVisible={setVisible} data={editData} />
    </>
  );
}

export default ListPackages;
