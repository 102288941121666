import React from 'react';
import "./LoadingPage.css"

function LoadingPage() {
  return <main>


<div class="content">
   <div class="loading">
<p>loading</p>
      <span></span>
   </div>
</div>
</main>;
}

export default LoadingPage;
